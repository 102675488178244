import { MIN_CARD_DIMENSIONS } from '$shared/constants/dimensions';
import { createPage } from '$store/index';

import { PageOutcome } from './controller';
import { InitialState, PageState } from './state';

export * from './controller';
export * from './schema';

const dimensions = MIN_CARD_DIMENSIONS;

const forceModal = false;

export const expressTheme = {
  load: () => import('./page-fetch'),
  create(initialState: InitialState & Partial<PageState>) {
    return createPage<PageOutcome, Partial<PageState>>(this.load, {
      initialState,
      dimensions,
      forceModal,
    });
  },
};
