import { CountryId } from '$shared/types/countries';

import {
  CONSENT_PROFILES,
  CONSENT_VALUES,
  ConsentProfile,
} from '$shared/types/marketing-consent';
import {
  defaultMarketingConsentProfiles,
  marketingConsentProfileOverrides,
} from '$shared/constants/profiles';

export const DefaultTypesOfConsent = {
  [CONSENT_VALUES.implicit]: true,
  [CONSENT_VALUES.explicit]: false,
  [CONSENT_VALUES.soft]: true,
};

export const MARKETING_CONSENT_DEFAULT = {
  countryId: 'US' as CountryId,
  hasUserConsented: DefaultTypesOfConsent[CONSENT_VALUES.implicit],
  consentProfile: CONSENT_PROFILES.adobeIdSignUp,
};

export const getCountryConsentType = (
  country: CountryId,
  consentProfile: ConsentProfile
) =>
  marketingConsentProfileOverrides?.[country]?.[consentProfile] ||
  defaultMarketingConsentProfiles[consentProfile];
