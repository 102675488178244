import { createPage } from '$store/index';

import { PageOutcome } from './controller';
import { PageState } from './state';
import { InitialState } from "$pages/variants/unified-mobile/state";

export * from './controller';
export * from './schema';

const dimensions = { width: '100%', height: '100%' };

const forceModal = false;

export const unifiedMobile = {
  load: () => import('./page-fetch'),
  create(initialState: InitialState & Partial<PageState>) {
    return createPage<PageOutcome, Partial<PageState>>(this.load, {
      initialState,
      dimensions,
      forceModal,
    });
  },
};
