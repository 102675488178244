import { HasOutcome } from '$scripts/base-controller';
import { AppEvents } from '$scripts/events/events';
import { Outcome } from '$scripts/outcome';
import { getApplicationState } from '$store/application-store';
import { GetOutcomes, RemainingOutcomes } from '$traits/helpers';
import {
  handleOutcome as handleSignInWithPopup,
  outcomeAction as signInWithPopupAction,
} from './with-popup';
import {
  handleOutcome as handleSignInWithRedirect,
  outcomeAction as signInWithRedirectAction,
} from './with-redirect';

interface ActionDependencies {
  getApplicationState: typeof getApplicationState;
}

interface CombinedOutcomeAction {
  signInWithPopupAction: typeof signInWithPopupAction;
  signInWithRedirectAction: typeof signInWithRedirectAction;
}

function createAction(
  { getApplicationState }: ActionDependencies,
  { signInWithPopupAction, signInWithRedirectAction }: CombinedOutcomeAction
) {
  return function <TBase extends HasOutcome<any>>(base: TBase) {
    const signInWithPopup = signInWithPopupAction(base);
    const signInWithRedirect = signInWithRedirectAction(base);
    return async function action() {
      const { config } = await getApplicationState();

      return config.popup ? signInWithPopup() : signInWithRedirect();
    };
  };
}

export const outcomeAction = createAction(
  { getApplicationState },
  { signInWithPopupAction, signInWithRedirectAction }
);

export type TraitOutcome = GetOutcomes<typeof outcomeAction>;

export function handleOutcome<T extends Outcome>(
  events: AppEvents,
  outcome: T
) {
  outcome = handleSignInWithPopup(events, outcome);
  outcome = handleSignInWithRedirect(events, outcome);

  return outcome as RemainingOutcomes<T, TraitOutcome>;
}

export const trait = {
  action: outcomeAction,
  handle: handleOutcome,
};
