import { MIN_CARD_DIMENSIONS } from '$shared/constants/dimensions';
import { createPage } from '$store/index';

import { PageOutcome as AccessDeniedPageOutcome } from './controller';

const dimensions = MIN_CARD_DIMENSIONS;

export const AccessDeniedPage = {
  load: () => import('./page-fetch'),
  create(initialState: {}) {
    return createPage<AccessDeniedPageOutcome, {}>(this.load, {
      initialState,
      dimensions,
      forceModal: false,
    });
  },
};
