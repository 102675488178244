import { labelStyling } from '$shared/mixins/label';
import { LitElement, css, html } from 'lit';
import { customElement, property } from 'lit/decorators';
import { LocalisedMonthObject } from '$locales/constants/months';

import '@spectrum-web-components/picker/sp-picker.js';

@customElement('sp-custom-select')
export class CustomSelect extends LitElement {
  @property()
  data: LocalisedMonthObject[] = [];

  @property()
  id: string = '';

  @property()
  width?: number;

  @property()
  invalid: boolean = false;

  static styles = css`
    #month-of-birth-picker {
      --system-spectrum-picker-background-color-hover: transparent;
      --spectrum-picker-background-color-default: transparent;
      --spectrum-picker-background-color-default-open: transparent;
      --spectrum-picker-background-color-key-focus: transparent;
      --system-spectrum-picker-background-color-active: transparent;

      --spectrum-picker-border-color-default: transparent;
      --highcontrast-picker-border-color-default: transparent;
      --system-spectrum-picker-border-color-active: transparent;
      --spectrum-picker-border-color-default-open: transparent;
      --spectrum-picker-border-color-key-focus: transparent;

      --spectrum-picker-focus-indicator-color: transparent;
      --spectrum-picker-focus-indicator-thickness: 0px;

      --spectrum-picker-border-color-error-default: transparent;
      --spectrum-negative-border-color-focus: transparent;
      --spectrum-negative-border-color-hover: transparent;
      --spectrum-negative-border-color-focus-hover: transparent;
      --spectrum-negative-border-color-down: transparent;
      --spectrum-negative-border-color-key-focus: transparent;

      --highcontrast-picker-icon-color-error-default: var(
        --spectrum-semantic-negative-icon-color
      );

      --mod-picker-spacing-edge-to-text: 0px;

      border-bottom: 1px solid var(--spectrum-gray-400);
      margin-top: 0.5px;
    }

    .validation-icon {
      color: green;
    }

    #month-of-birth-picker:focus {
      border-bottom: 2px solid var(--spectrum-blue-800) !important;
      margin-bottom: -1px;
    }

    sp-field-label {
      ${labelStyling()}
    }
  `;

  render() {
    return html` <div>
      <sp-field-label for="${this.id}" size="m">
        <slot></slot>
      </sp-field-label>
      <sp-picker
        style=${this.width ? `width: ${this.width}px` : null}
        id="${this.id}"
        value=${this.data?.[0]?.id}
        size="m"
        ?invalid=${this.invalid}
        @change=${(e: any) => {
          this.dispatchEvent(
            new CustomEvent('sp-custom-select-change', {
              bubbles: true,
              composed: true,
              detail: {
                value: e.target.value,
              },
            })
          );
        }}
      >
        ${this.data.map(
          entry =>
            html`<sp-menu-item
              style=${this.width ? `width: ${this.width}px` : null}
              value=${entry.id}
              data-id=${entry.id}
            >
              ${entry.value()}
            </sp-menu-item>`
        )}
      </sp-picker>
    </div>`;
  }
}
