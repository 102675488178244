import { SentryError, convertError } from '@sentry/shared';

import { initLocalisation } from '$locales/helpers';
import '$pages/app-wrapper';
import { CriticalOutcome } from '$scripts/outcome';
import { queryState } from '$scripts/query-state';
import { broadcastLastResortError } from '$shared/helpers';

import { getApplicationState } from './store';

import {
  completeAccountFlow,
  criticalErrorFlow,
  expressSignInFlow,
  signInFlow,
  unifiedMobileFlow,
  unrecoverableErrorFlow,
} from '$flows/index';
import { setupServiceWorker } from './mocks/origins';

type FlowFn = (events: any, config: any) => Promise<any>;
const FLOWS = new Map<string, FlowFn>([
  ['large-buttons', signInFlow],
  ['express-theme', expressSignInFlow],
  ['unified-mobile', unifiedMobileFlow],
  ['complete-account', completeAccountFlow],
]);

/**
 * Load Locales
 */

window.addEventListener('error', event => {
  event.preventDefault();

  try {
    if (event instanceof ErrorEvent) {
      unrecoverableErrorFlow(
        null as any,
        new CriticalOutcome(
          SentryError.unrecoverable('Global uncaught error.', event.error)
        )
      );
    }
  } catch (e) {
    broadcastLastResortError(e);
  }
});

// Set application language (default is en-us)
try {
  const qs = queryState.fromQueryString(document.location.search.substring(1));

  initLocalisation(qs.locale);
} catch (e) {
  initLocalisation('en-us');
}

window.addEventListener('DOMContentLoaded', async () => {
  try {
    const requestedFlow = document.location.hash.substring(1) ?? '';
    if (!FLOWS.has(requestedFlow)) {
      throw new Error(`Unknown flow: ${requestedFlow}`);
    }

    const flow = FLOWS.get(requestedFlow);

    await setupServiceWorker();
    const { events, config } = await getApplicationState();
    await flow!(events, config);
  } catch (e) {
    const error = convertError(e);
    const { events } = await getApplicationState().catch(() => {
      return {
        events: null,
      } as any;
    });

    if (events) {
      criticalErrorFlow(events, new CriticalOutcome(error));
    } else {
      unrecoverableErrorFlow(events, new CriticalOutcome(error));
    }
  }
});
