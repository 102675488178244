/* eslint-disable max-len */
import { html } from 'lit';

export const defaultAvatarT2T3 = html`<svg 
    xmlns="http://www.w3.org/2000/svg" 
    width="64" 
    height="64" 
    viewBox="0 0 64 64">
        <defs>
            <style>.cls-1{isolation:isolate;}.cls-2{fill:#f98e00;}.cls-3{opacity:0.25;mix-blend-mode:multiply;}.cls-4{fill:#261900;}</style>
        </defs>
        <title>1_#f98e00</title>
        <g class="cls-1">
            <g id="Layer_1" data-name="Layer 1">
                <circle class="cls-2" cx="31.99999" cy="32" r="32"/>
                <g class="cls-3">
                    <path class="cls-4" d="M55.59132,39l3.91351,9.35389A32.00113,32.00113,0,0,0,35.27406.16554L18.15175,39Z"/>
                </g>
            </g>
        </g>
    </svg>`;