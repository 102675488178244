/* eslint-disable max-len */
import { html } from 'lit';

export const defaultAvatarT1 = html`<svg 
    xmlns="http://www.w3.org/2000/svg" 
    height="100%"
    width="100%" 
    viewBox="0 0 64 64">
        <defs>
            <style>.cls-1{isolation:isolate;}.cls-2{fill:#00cfff;}.cls-3{opacity:0.25;mix-blend-mode:multiply;}.cls-4{fill:#001d26;}</style>
        </defs>
        <title>1_#00cfff</title>
        <g class="cls-1">
            <g id="Layer_1" data-name="Layer 1">
                <circle class="cls-2" cx="32" cy="32" r="32"/>
                <g class="cls-3">
                    <path class="cls-4" d="M55.59133,39l3.91352,9.35389A32.00113,32.00113,0,0,0,35.27407.16554L18.15176,39Z"/>
                </g>
            </g>
        </g>
    </svg>`;