import { i18n } from '$locales/helpers';

const COUNTRY_LIST = {
  AF: () => i18n().formatMessage({ id: 'AF', defaultMessage: 'Afghanistan' }),
  AL: () => i18n().formatMessage({ id: 'AL', defaultMessage: 'Albania' }),
  DZ: () => i18n().formatMessage({ id: 'DZ', defaultMessage: 'Algeria' }),
  AS: () =>
    i18n().formatMessage({
      id: 'AS',
      defaultMessage: 'American Samoa',
    }),
  AD: () => i18n().formatMessage({ id: 'AD', defaultMessage: 'Andorra' }),
  AO: () => i18n().formatMessage({ id: 'AO', defaultMessage: 'Angola' }),
  AI: () => i18n().formatMessage({ id: 'AI', defaultMessage: 'Anguilla' }),
  AQ: () => i18n().formatMessage({ id: 'AQ', defaultMessage: 'Antarctica' }),
  AG: () =>
    i18n().formatMessage({
      id: 'AG',
      defaultMessage: 'Antigua and Barbuda',
    }),
  AR: () => i18n().formatMessage({ id: 'AR', defaultMessage: 'Argentina' }),
  AM: () => i18n().formatMessage({ id: 'AM', defaultMessage: 'Armenia' }),
  AW: () => i18n().formatMessage({ id: 'AW', defaultMessage: 'Aruba' }),
  AU: () => i18n().formatMessage({ id: 'AU', defaultMessage: 'Australia' }),
  AT: () => i18n().formatMessage({ id: 'AT', defaultMessage: 'Austria' }),
  AZ: () => i18n().formatMessage({ id: 'AZ', defaultMessage: 'Azerbaijan' }),
  BS: () => i18n().formatMessage({ id: 'BS', defaultMessage: 'Bahamas' }),
  BH: () => i18n().formatMessage({ id: 'BH', defaultMessage: 'Bahrain' }),
  BD: () => i18n().formatMessage({ id: 'BD', defaultMessage: 'Bangladesh' }),
  BB: () => i18n().formatMessage({ id: 'BB', defaultMessage: 'Barbados' }),
  BY: () => i18n().formatMessage({ id: 'BY', defaultMessage: 'Belarus' }),
  BE: () => i18n().formatMessage({ id: 'BE', defaultMessage: 'Belgium' }),
  BZ: () => i18n().formatMessage({ id: 'BZ', defaultMessage: 'Belize' }),
  BJ: () => i18n().formatMessage({ id: 'BJ', defaultMessage: 'Benin' }),
  BM: () => i18n().formatMessage({ id: 'BM', defaultMessage: 'Bermuda' }),
  BT: () => i18n().formatMessage({ id: 'BT', defaultMessage: 'Bhutan' }),
  BO: () => i18n().formatMessage({ id: 'BO', defaultMessage: 'Bolivia' }),
  BA: () =>
    i18n().formatMessage({
      id: 'BA',
      defaultMessage: 'Bosnia and Herzegovina',
    }),
  BW: () => i18n().formatMessage({ id: 'BW', defaultMessage: 'Botswana' }),
  BR: () => i18n().formatMessage({ id: 'BR', defaultMessage: 'Brazil' }),
  IO: () =>
    i18n().formatMessage({
      id: 'IO',
      defaultMessage: 'British Indian Ocean',
    }),
  BN: () => i18n().formatMessage({ id: 'BN', defaultMessage: 'Brunei' }),
  BG: () => i18n().formatMessage({ id: 'BG', defaultMessage: 'Bulgaria' }),
  BF: () =>
    i18n().formatMessage({
      id: 'BF',
      defaultMessage: 'Burkina Faso',
    }),
  BI: () => i18n().formatMessage({ id: 'BI', defaultMessage: 'Burundi' }),
  KH: () => i18n().formatMessage({ id: 'KH', defaultMessage: 'Cambodia' }),
  CM: () => i18n().formatMessage({ id: 'CM', defaultMessage: 'Cameroon' }),
  CA: () => i18n().formatMessage({ id: 'CA', defaultMessage: 'Canada' }),
  CV: () => i18n().formatMessage({ id: 'CV', defaultMessage: 'Cape Verde' }),
  KY: () =>
    i18n().formatMessage({
      id: 'KY',
      defaultMessage: 'Cayman Islands',
    }),
  CF: () =>
    i18n().formatMessage({
      id: 'CF',
      defaultMessage: 'Central African Republic',
    }),
  TD: () => i18n().formatMessage({ id: 'TD', defaultMessage: 'Chad' }),
  CL: () => i18n().formatMessage({ id: 'CL', defaultMessage: 'Chile' }),
  CN: () => i18n().formatMessage({ id: 'CN', defaultMessage: 'China' }),
  CX: () =>
    i18n().formatMessage({
      id: 'CX',
      defaultMessage: 'Christmas Island',
    }),
  CC: () =>
    i18n().formatMessage({
      id: 'CC',
      defaultMessage: 'Cocos (Keeling) Islands',
    }),
  CO: () => i18n().formatMessage({ id: 'CO', defaultMessage: 'Colombia' }),
  KM: () => i18n().formatMessage({ id: 'KM', defaultMessage: 'Comoros' }),
  CG: () =>
    i18n().formatMessage({
      id: 'CG',
      defaultMessage: 'Congo, Republic of the',
    }),
  CK: () =>
    i18n().formatMessage({
      id: 'CK',
      defaultMessage: 'Cook Islands',
    }),
  CR: () => i18n().formatMessage({ id: 'CR', defaultMessage: 'Costa Rica' }),
  HR: () => i18n().formatMessage({ id: 'HR', defaultMessage: 'Croatia' }),
  CY: () => i18n().formatMessage({ id: 'CY', defaultMessage: 'Cyprus' }),
  CZ: () =>
    i18n().formatMessage({
      id: 'CZ',
      defaultMessage: 'Czech Republic',
    }),
  CD: () =>
    i18n().formatMessage({
      id: 'CD',
      defaultMessage: 'Democratic Republic of the Congo',
    }),
  DK: () => i18n().formatMessage({ id: 'DK', defaultMessage: 'Denmark' }),
  DJ: () => i18n().formatMessage({ id: 'DJ', defaultMessage: 'Djibouti' }),
  DM: () => i18n().formatMessage({ id: 'DM', defaultMessage: 'Dominica' }),
  DO: () =>
    i18n().formatMessage({
      id: 'DO',
      defaultMessage: 'Dominican Republic',
    }),
  TL: () => i18n().formatMessage({ id: 'TL', defaultMessage: 'East Timor' }),
  EC: () => i18n().formatMessage({ id: 'EC', defaultMessage: 'Ecuador' }),
  EG: () => i18n().formatMessage({ id: 'EG', defaultMessage: 'Egypt' }),
  SV: () => i18n().formatMessage({ id: 'SV', defaultMessage: 'El Salvador' }),
  GQ: () =>
    i18n().formatMessage({
      id: 'GQ',
      defaultMessage: 'Equatorial Guinea',
    }),
  ER: () => i18n().formatMessage({ id: 'ER', defaultMessage: 'Eritrea' }),
  EE: () => i18n().formatMessage({ id: 'EE', defaultMessage: 'Estonia' }),
  ET: () => i18n().formatMessage({ id: 'ET', defaultMessage: 'Ethiopia' }),
  FK: () =>
    i18n().formatMessage({
      id: 'FK',
      defaultMessage: 'Falkland Islands (Malvinas)',
    }),
  FO: () =>
    i18n().formatMessage({
      id: 'FO',
      defaultMessage: 'Faroe Islands',
    }),
  FJ: () => i18n().formatMessage({ id: 'FJ', defaultMessage: 'Fiji' }),
  FI: () => i18n().formatMessage({ id: 'FI', defaultMessage: 'Finland' }),
  FR: () => i18n().formatMessage({ id: 'FR', defaultMessage: 'France' }),
  GF: () =>
    i18n().formatMessage({
      id: 'GF',
      defaultMessage: 'French Guiana',
    }),
  PF: () =>
    i18n().formatMessage({
      id: 'PF',
      defaultMessage: 'French Polynesia',
    }),
  GA: () => i18n().formatMessage({ id: 'GA', defaultMessage: 'Gabon' }),
  GM: () => i18n().formatMessage({ id: 'GM', defaultMessage: 'Gambia' }),
  GE: () => i18n().formatMessage({ id: 'GE', defaultMessage: 'Georgia' }),
  DE: () => i18n().formatMessage({ id: 'DE', defaultMessage: 'Germany' }),
  GH: () => i18n().formatMessage({ id: 'GH', defaultMessage: 'Ghana' }),
  GI: () => i18n().formatMessage({ id: 'GI', defaultMessage: 'Gibraltar' }),
  GR: () => i18n().formatMessage({ id: 'GR', defaultMessage: 'Greece' }),
  GL: () => i18n().formatMessage({ id: 'GL', defaultMessage: 'Greenland' }),
  GD: () => i18n().formatMessage({ id: 'GD', defaultMessage: 'Grenada' }),
  GP: () => i18n().formatMessage({ id: 'GP', defaultMessage: 'Guadeloupe' }),
  GT: () => i18n().formatMessage({ id: 'GT', defaultMessage: 'Guatemala' }),
  GN: () => i18n().formatMessage({ id: 'GN', defaultMessage: 'Guinea' }),
  GW: () =>
    i18n().formatMessage({
      id: 'GW',
      defaultMessage: 'Guinea-Bissau',
    }),
  GY: () => i18n().formatMessage({ id: 'GY', defaultMessage: 'Guyana' }),
  HT: () => i18n().formatMessage({ id: 'HT', defaultMessage: 'Haiti' }),
  HN: () => i18n().formatMessage({ id: 'HN', defaultMessage: 'Honduras' }),
  HK: () =>
    i18n().formatMessage({
      id: 'HK',
      defaultMessage: 'Hong Kong SAR of China',
    }),
  HU: () => i18n().formatMessage({ id: 'HU', defaultMessage: 'Hungary' }),
  IS: () => i18n().formatMessage({ id: 'IS', defaultMessage: 'Iceland' }),
  IN: () => i18n().formatMessage({ id: 'IN', defaultMessage: 'India' }),
  ID: () => i18n().formatMessage({ id: 'ID', defaultMessage: 'Indonesia' }),
  IQ: () => i18n().formatMessage({ id: 'IQ', defaultMessage: 'Iraq' }),
  IE: () => i18n().formatMessage({ id: 'IE', defaultMessage: 'Ireland' }),
  IL: () => i18n().formatMessage({ id: 'IL', defaultMessage: 'Israel' }),
  IT: () => i18n().formatMessage({ id: 'IT', defaultMessage: 'Italy' }),
  CI: () =>
    i18n().formatMessage({
      id: 'CI',
      defaultMessage: "Ivory Coast (Côte d'Ivoire)",
    }),
  JM: () => i18n().formatMessage({ id: 'JM', defaultMessage: 'Jamaica' }),
  JP: () => i18n().formatMessage({ id: 'JP', defaultMessage: 'Japan' }),
  JO: () => i18n().formatMessage({ id: 'JO', defaultMessage: 'Jordan' }),
  KZ: () => i18n().formatMessage({ id: 'KZ', defaultMessage: 'Kazakhstan' }),
  KE: () => i18n().formatMessage({ id: 'KE', defaultMessage: 'Kenya' }),
  KI: () => i18n().formatMessage({ id: 'KI', defaultMessage: 'Kiribati' }),
  KR: () =>
    i18n().formatMessage({
      id: 'KR',
      defaultMessage: 'Korea, South',
    }),
  KV: () => i18n().formatMessage({ id: 'KV', defaultMessage: 'Kosovo' }),
  KW: () => i18n().formatMessage({ id: 'KW', defaultMessage: 'Kuwait' }),
  KG: () => i18n().formatMessage({ id: 'KG', defaultMessage: 'Kyrgyzstan' }),
  LA: () => i18n().formatMessage({ id: 'LA', defaultMessage: 'Laos' }),
  LV: () => i18n().formatMessage({ id: 'LV', defaultMessage: 'Latvia' }),
  LB: () => i18n().formatMessage({ id: 'LB', defaultMessage: 'Lebanon' }),
  LS: () => i18n().formatMessage({ id: 'LS', defaultMessage: 'Lesotho' }),
  LR: () => i18n().formatMessage({ id: 'LR', defaultMessage: 'Liberia' }),
  LY: () => i18n().formatMessage({ id: 'LY', defaultMessage: 'Libya' }),
  LI: () =>
    i18n().formatMessage({
      id: 'LI',
      defaultMessage: 'Liechtenstein',
    }),
  LT: () => i18n().formatMessage({ id: 'LT', defaultMessage: 'Lithuania' }),
  LU: () => i18n().formatMessage({ id: 'LU', defaultMessage: 'Luxembourg' }),
  MO: () =>
    i18n().formatMessage({
      id: 'MO',
      defaultMessage: 'Macau SAR of China',
    }),
  MK: () =>
    i18n().formatMessage({
      id: 'MK',
      defaultMessage: 'Macedonia, Republic of',
    }),
  MG: () => i18n().formatMessage({ id: 'MG', defaultMessage: 'Madagascar' }),
  MW: () => i18n().formatMessage({ id: 'MW', defaultMessage: 'Malawi' }),
  MY: () => i18n().formatMessage({ id: 'MY', defaultMessage: 'Malaysia' }),
  MV: () => i18n().formatMessage({ id: 'MV', defaultMessage: 'Maldives' }),
  ML: () => i18n().formatMessage({ id: 'ML', defaultMessage: 'Mali' }),
  MT: () => i18n().formatMessage({ id: 'MT', defaultMessage: 'Malta' }),
  MH: () =>
    i18n().formatMessage({
      id: 'MH',
      defaultMessage: 'Marshall Islands',
    }),
  MQ: () => i18n().formatMessage({ id: 'MQ', defaultMessage: 'Martinique' }),
  MR: () => i18n().formatMessage({ id: 'MR', defaultMessage: 'Mauritania' }),
  MU: () => i18n().formatMessage({ id: 'MU', defaultMessage: 'Mauritius' }),
  YT: () => i18n().formatMessage({ id: 'YT', defaultMessage: 'Mayotte' }),
  MX: () => i18n().formatMessage({ id: 'MX', defaultMessage: 'Mexico' }),
  FM: () => i18n().formatMessage({ id: 'FM', defaultMessage: 'Micronesia' }),
  MD: () => i18n().formatMessage({ id: 'MD', defaultMessage: 'Moldova' }),
  MC: () => i18n().formatMessage({ id: 'MC', defaultMessage: 'Monaco' }),
  MN: () => i18n().formatMessage({ id: 'MN', defaultMessage: 'Mongolia' }),
  ME: () => i18n().formatMessage({ id: 'ME', defaultMessage: 'Montenegro' }),
  MS: () => i18n().formatMessage({ id: 'MS', defaultMessage: 'Montserrat' }),
  MA: () => i18n().formatMessage({ id: 'MA', defaultMessage: 'Morocco' }),
  MZ: () => i18n().formatMessage({ id: 'MZ', defaultMessage: 'Mozambique' }),
  MM: () => i18n().formatMessage({ id: 'MM', defaultMessage: 'Myanmar' }),
  NA: () => i18n().formatMessage({ id: 'NA', defaultMessage: 'Namibia' }),
  NR: () => i18n().formatMessage({ id: 'NR', defaultMessage: 'Nauru' }),
  NP: () => i18n().formatMessage({ id: 'NP', defaultMessage: 'Nepal' }),
  NL: () => i18n().formatMessage({ id: 'NL', defaultMessage: 'Netherlands' }),
  AN: () =>
    i18n().formatMessage({
      id: 'AN',
      defaultMessage: 'Netherlands Antilles',
    }),
  NC: () =>
    i18n().formatMessage({
      id: 'NC',
      defaultMessage: 'New Caledonia',
    }),
  NZ: () => i18n().formatMessage({ id: 'NZ', defaultMessage: 'New Zealand' }),
  NI: () => i18n().formatMessage({ id: 'NI', defaultMessage: 'Nicaragua' }),
  NE: () => i18n().formatMessage({ id: 'NE', defaultMessage: 'Niger' }),
  NG: () => i18n().formatMessage({ id: 'NG', defaultMessage: 'Nigeria' }),
  NU: () => i18n().formatMessage({ id: 'NU', defaultMessage: 'Niue' }),
  NF: () =>
    i18n().formatMessage({
      id: 'NF',
      defaultMessage: 'Norfolk Island',
    }),
  NO: () => i18n().formatMessage({ id: 'NO', defaultMessage: 'Norway' }),
  OM: () => i18n().formatMessage({ id: 'OM', defaultMessage: 'Oman' }),
  PK: () => i18n().formatMessage({ id: 'PK', defaultMessage: 'Pakistan' }),
  PS: () =>
    i18n().formatMessage({
      id: 'PS',
      defaultMessage: 'Palestinian Territory',
    }),
  PA: () => i18n().formatMessage({ id: 'PA', defaultMessage: 'Panama' }),
  PG: () =>
    i18n().formatMessage({
      id: 'PG',
      defaultMessage: 'Papua New Guinea',
    }),
  PY: () => i18n().formatMessage({ id: 'PY', defaultMessage: 'Paraguay' }),
  PE: () => i18n().formatMessage({ id: 'PE', defaultMessage: 'Peru' }),
  PH: () => i18n().formatMessage({ id: 'PH', defaultMessage: 'Philippines' }),
  PN: () =>
    i18n().formatMessage({
      id: 'PN',
      defaultMessage: 'Pitcairn Island',
    }),
  PL: () => i18n().formatMessage({ id: 'PL', defaultMessage: 'Poland' }),
  PT: () => i18n().formatMessage({ id: 'PT', defaultMessage: 'Portugal' }),
  QA: () => i18n().formatMessage({ id: 'QA', defaultMessage: 'Qatar' }),
  RE: () => i18n().formatMessage({ id: 'RE', defaultMessage: 'Réunion' }),
  RO: () => i18n().formatMessage({ id: 'RO', defaultMessage: 'Romania' }),
  RU: () => i18n().formatMessage({ id: 'RU', defaultMessage: 'Russia' }),
  RW: () => i18n().formatMessage({ id: 'RW', defaultMessage: 'Rwanda' }),
  SH: () =>
    i18n().formatMessage({
      id: 'SH',
      defaultMessage: 'Saint Helena',
    }),
  KN: () =>
    i18n().formatMessage({
      id: 'KN',
      defaultMessage: 'Saint Kitts and Nevis',
    }),
  LC: () => i18n().formatMessage({ id: 'LC', defaultMessage: 'Saint Lucia' }),
  PM: () =>
    i18n().formatMessage({
      id: 'PM',
      defaultMessage: 'Saint Pierre and Miquelon',
    }),
  VC: () =>
    i18n().formatMessage({
      id: 'VC',
      defaultMessage: 'Saint Vincent and the Grenadines',
    }),
  WS: () => i18n().formatMessage({ id: 'WS', defaultMessage: 'Samoa' }),
  SM: () => i18n().formatMessage({ id: 'SM', defaultMessage: 'San Marino' }),
  ST: () =>
    i18n().formatMessage({
      id: 'ST',
      defaultMessage: 'São Tome and Principe',
    }),
  SA: () =>
    i18n().formatMessage({
      id: 'SA',
      defaultMessage: 'Saudi Arabia',
    }),
  SN: () => i18n().formatMessage({ id: 'SN', defaultMessage: 'Senegal' }),
  RS: () => i18n().formatMessage({ id: 'RS', defaultMessage: 'Serbia' }),
  SC: () => i18n().formatMessage({ id: 'SC', defaultMessage: 'Seychelles' }),
  SL: () =>
    i18n().formatMessage({
      id: 'SL',
      defaultMessage: 'Sierra Leone',
    }),
  SG: () => i18n().formatMessage({ id: 'SG', defaultMessage: 'Singapore' }),
  SK: () => i18n().formatMessage({ id: 'SK', defaultMessage: 'Slovakia' }),
  SI: () => i18n().formatMessage({ id: 'SI', defaultMessage: 'Slovenia' }),
  SB: () =>
    i18n().formatMessage({
      id: 'SB',
      defaultMessage: 'Solomon Islands',
    }),
  SO: () => i18n().formatMessage({ id: 'SO', defaultMessage: 'Somalia' }),
  ZA: () =>
    i18n().formatMessage({
      id: 'ZA',
      defaultMessage: 'South Africa',
    }),
  GS: () =>
    i18n().formatMessage({
      id: 'GS',
      defaultMessage: 'South Georgia and the South Sandwich Islands',
    }),
  ES: () => i18n().formatMessage({ id: 'ES', defaultMessage: 'Spain' }),
  LK: () => i18n().formatMessage({ id: 'LK', defaultMessage: 'Sri Lanka' }),
  SR: () => i18n().formatMessage({ id: 'SR', defaultMessage: 'Suriname' }),
  SD: () => i18n().formatMessage({ id: 'SD', defaultMessage: 'Sudan' }),
  SS: () => i18n().formatMessage({ id: 'SS', defaultMessage: 'South Sudan' }),
  SJ: () =>
    i18n().formatMessage({
      id: 'SJ',
      defaultMessage: 'Svalbard and Jan Mayen',
    }),
  SZ: () => i18n().formatMessage({ id: 'SZ', defaultMessage: 'Swaziland' }),
  SE: () => i18n().formatMessage({ id: 'SE', defaultMessage: 'Sweden' }),
  CH: () => i18n().formatMessage({ id: 'CH', defaultMessage: 'Switzerland' }),
  TW: () =>
    i18n().formatMessage({
      id: 'TW',
      defaultMessage: 'Taiwan Region',
    }),
  TJ: () => i18n().formatMessage({ id: 'TJ', defaultMessage: 'Tajikistan' }),
  TZ: () => i18n().formatMessage({ id: 'TZ', defaultMessage: 'Tanzania' }),
  TH: () => i18n().formatMessage({ id: 'TH', defaultMessage: 'Thailand' }),
  TG: () => i18n().formatMessage({ id: 'TG', defaultMessage: 'Togo' }),
  TK: () => i18n().formatMessage({ id: 'TK', defaultMessage: 'Tokelau' }),
  TO: () => i18n().formatMessage({ id: 'TO', defaultMessage: 'Tonga' }),
  TT: () =>
    i18n().formatMessage({
      id: 'TT',
      defaultMessage: 'Trinidad and Tobago',
    }),
  TN: () => i18n().formatMessage({ id: 'TN', defaultMessage: 'Tunisia' }),
  TR: () => i18n().formatMessage({ id: 'TR', defaultMessage: 'Turkey' }),
  TM: () =>
    i18n().formatMessage({
      id: 'TM',
      defaultMessage: 'Turkmenistan',
    }),
  TC: () =>
    i18n().formatMessage({
      id: 'TC',
      defaultMessage: 'Turks and Caicos Islands',
    }),
  TV: () => i18n().formatMessage({ id: 'TV', defaultMessage: 'Tuvalu' }),
  UG: () => i18n().formatMessage({ id: 'UG', defaultMessage: 'Uganda' }),
  UA: () => i18n().formatMessage({ id: 'UA', defaultMessage: 'Ukraine' }),
  AE: () =>
    i18n().formatMessage({
      id: 'AE',
      defaultMessage: 'United Arab Emirates',
    }),
  GB: () =>
    i18n().formatMessage({
      id: 'GB',
      defaultMessage: 'United Kingdom',
    }),
  US: () =>
    i18n().formatMessage({
      id: 'US',
      defaultMessage: 'United States',
    }),
  UM: () =>
    i18n().formatMessage({
      id: 'UM',
      defaultMessage: 'United States Minor Outlying Islands',
    }),
  UY: () => i18n().formatMessage({ id: 'UY', defaultMessage: 'Uruguay' }),
  UZ: () => i18n().formatMessage({ id: 'UZ', defaultMessage: 'Uzbekistan' }),
  VU: () => i18n().formatMessage({ id: 'VU', defaultMessage: 'Vanuatu' }),
  VA: () =>
    i18n().formatMessage({
      id: 'VA',
      defaultMessage: 'Vatican City',
    }),
  VE: () => i18n().formatMessage({ id: 'VE', defaultMessage: 'Venezuela' }),
  VN: () => i18n().formatMessage({ id: 'VN', defaultMessage: 'Vietnam' }),
  VG: () =>
    i18n().formatMessage({
      id: 'VG',
      defaultMessage: 'Virgin Islands, British',
    }),
  WF: () =>
    i18n().formatMessage({
      id: 'WF',
      defaultMessage: 'Wallis and Futuna',
    }),
  EH: () =>
    i18n().formatMessage({
      id: 'EH',
      defaultMessage: 'Western Sahara',
    }),
  YE: () => i18n().formatMessage({ id: 'YE', defaultMessage: 'Yemen' }),
  ZM: () => i18n().formatMessage({ id: 'ZM', defaultMessage: 'Zambia' }),
  ZW: () => i18n().formatMessage({ id: 'ZW', defaultMessage: 'Zimbabwe' }),
};
export { COUNTRY_LIST };
