import {
  HasOutcome,
  OutcomeListener,
  PageController,
} from '$scripts/base-controller';
import { Outcome } from '$scripts/outcome';
import {
  SOCIAL_PROVIDERS,
  SocialProvider,
} from '$shared/types/social-provider';

import { ObjectValues } from '$shared/helpers';
import { PageState } from './state';

export const LINK_OPTIONS = {
  signIn: 'sign-in',
  createAccount: 'create-account',
  skip: 'skip',
} as const;

export const DEEPLINK_OPTIONS = {
  passkey: 'passkey',
  microsoft: 'microsoft',
  line: 'line',
} as const;

export type DeeplinkOptions = ObjectValues<typeof DEEPLINK_OPTIONS>;

export const deeplinkSocialProvidersMap = new Map<
  SocialProvider,
  DeeplinkOptions
>([
  [SOCIAL_PROVIDERS.microsoft, DEEPLINK_OPTIONS.microsoft],
  [SOCIAL_PROVIDERS.line, DEEPLINK_OPTIONS.line],
]);

export const IDP_FLOW_QUERY = {
  passkey: 'idp_flow=passkeys.deep_link.web',
  microsoft: 'idp_flow=social.deep_link.web&provider_id=microsoft',
  line: 'idp_flow=social.deep_link.web&provider_id=line',
} as const;

export type IdpFlowQuery = ObjectValues<typeof IDP_FLOW_QUERY>;

export const idpFlowQueryMap = new Map<SocialProvider, IdpFlowQuery>([
  [SOCIAL_PROVIDERS.microsoft, IDP_FLOW_QUERY.microsoft],
  [SOCIAL_PROVIDERS.line, IDP_FLOW_QUERY.line],
]);

export class ProviderOutcome extends Outcome<{
  provider: SocialProvider;
}> {}
export class DeeplinkOutcome extends Outcome<{
  deeplink: string;
  query: string;
}> {}

export class SignInLinkOutcome extends Outcome<{
  option: typeof LINK_OPTIONS.signIn;
}> {}
export class CreateAccountLinkOutcome extends Outcome<{
  option: typeof LINK_OPTIONS.createAccount;
}> {}
export class SkipLinkOutcome extends Outcome<{
  option: typeof LINK_OPTIONS.skip;
}> {}

export type PageOutcome =
  | ProviderOutcome
  | DeeplinkOutcome
  | SignInLinkOutcome
  | CreateAccountLinkOutcome
  | SkipLinkOutcome;

export class Controller
  extends PageController<PageOutcome, PageState>
  implements HasOutcome<PageOutcome>
{
  @OutcomeListener()
  async chooseProvider(provider: SocialProvider) {
    return new ProviderOutcome({ provider });
  }

  @OutcomeListener()
  async passkey() {
    return new DeeplinkOutcome({
      deeplink: DEEPLINK_OPTIONS.passkey,
      query: IDP_FLOW_QUERY.passkey,
    });
  }

  @OutcomeListener()
  async signIn() {
    return new SignInLinkOutcome({ option: LINK_OPTIONS.signIn });
  }

  @OutcomeListener()
  async createAccount() {
    return new CreateAccountLinkOutcome({ option: LINK_OPTIONS.createAccount });
  }

  @OutcomeListener()
  async skip() {
    return new SkipLinkOutcome({ option: LINK_OPTIONS.skip });
  }

  toggleShowMoreProviders() {
    this.state.toggleShowMoreProviders();
  }
}
