import { html } from 'lit';
import { i18n } from '$locales/helpers';
import { LINKS } from '$locales/constants/links';

export const getImplicitConsentElement = (canUserConsent: boolean) => html` <div
  class="marketing-consent--container implicit-consent"
>
  ${i18n().formatMessage(
    {
      id: 'marketing-consent.paragraph.implicit-text',
      defaultMessage: `
              {
                canUserConsent, select,
                  true {
                    By clicking Create account, I agree that:
                      <list>
                        <list-item>I have read and accepted the <terms-of-use-link>Terms of Use</terms-of-use-link>.</list-item>
                        <list-item>The <privacy-policy-info-share-link>Adobe family of companies</privacy-policy-info-share-link> may keep me informed with <marketing-email-link>personalized</marketing-email-link> emails about products and services.</list-item>
                      </list>
                    <container>See our <privacy-policy-link>Privacy Policy</privacy-policy-link> for more details or to opt-out at any time.</container>
                  }
                  other {
                    <container>
                      By clicking Create account, I agree that I have read and accepted the <terms-of-use-link>Terms of Use</terms-of-use-link> and <privacy-policy-link>Privacy Policy</privacy-policy-link>.
                    </container>
                  }
                }
              `,
      description:
        'Text block shown to users that need to agree implicity to company policies.',
    },
    {
      canUserConsent,
      list: (text: string[]) =>
        html`<ul>
          ${text}
        </ul>`,
      'list-item': (text: string[]) => html`<li>${text}</li>`,
      container: (text: string[]) => html`<div>${text}</div>`,
      'terms-of-use-link': (text: string[]) => html` <sp-link
        target="_blank"
        data-id="TermsOfUse"
        quiet
        href=${LINKS.termsOfUse()}
      >
        ${text}
      </sp-link>`,
      'privacy-policy-info-share-link': (text: string[]) => html`
        <sp-link
          target="_blank"
          data-id="PrivacyPolicyInfoShare"
          quiet
          href=${LINKS.privacyPolicyInfoShare()}
        >
          ${text}
        </sp-link>
      `,
      'privacy-policy-link': (text: string[]) => html`
        <sp-link
          target="_blank"
          data-id="PrivacyPolicy"
          quiet
          href=${LINKS.privacyPolicy()}
        >
          ${text}
        </sp-link>
      `,
      'marketing-email-link': (text: string[]) => html`
        <sp-link
          target="_blank"
          data-id="MarketingEmail"
          quiet
          href=${LINKS.marketingEmail()}
        >
          ${text}
        </sp-link>
      `,
    }
  )}
</div>`;

export const getExplicitOrSoftConsentElement = (
  _consentType: 'explicit' | 'soft',
  canUserConsent: boolean,
  hasUserConsented: boolean,
  setUserConsent: (value: boolean) => void
) => html`
  <div class=${`marketing-consent--container ${_consentType}-consent`}>
    ${i18n().formatMessage(
      {
        id: 'marketing-consent.paragraph.soft-or-explicit-text',
        defaultMessage: `
              {
                canUserConsent, select,
                  true {
                    <container>
                      The <privacy-policy-info-share-link>Adobe family of companies</privacy-policy-info-share-link> may keep me informed with <marketing-email-link>personalized</marketing-email-link> emails about products and services. 
                      See our <privacy-policy-link>Privacy Policy</privacy-policy-link> for more details or to opt-out at any time.
                      </container>
                      <checkbox>
                        {
                          consentType, select, 
                            soft {Please don't contact me via email}
                            other {Please contact me via email}
                        }
                    </checkbox>
                    <container>
                      By clicking Create account, I agree that I have read and accepted the <terms-of-use-link>Terms of Use</terms-of-use-link> and <privacy-policy-link>Privacy Policy</privacy-policy-link>.
                    </container>
                  }
                  other {
                    <container>
                      By clicking Create account, I agree that I have read and accepted the <terms-of-use-link>Terms of Use</terms-of-use-link> and <privacy-policy-link>Privacy Policy</privacy-policy-link>.
                    </container>
                  }
              }
            `,
      },
      {
        consentType: _consentType,
        canUserConsent,
        container: (text: string[]) => html`<div>${text}</div>`,
        checkbox: (text: string[]) => html`
          <sp-checkbox
            size="s"
            data-id="consent-checkbox"
            ?checked=${_consentType === 'soft'
              ? !hasUserConsented
              : hasUserConsented}
            @click=${() => setUserConsent(!hasUserConsented)}
            id="consent-checkbox"
          >
            ${text}
          </sp-checkbox>
        `,
        'terms-of-use-link': (text: string[]) => html` <sp-link
          target="_blank"
          data-id="TermsOfUse"
          quiet
          href=${LINKS.termsOfUse()}
          >${text}</sp-link
        >`,
        'privacy-policy-info-share-link': (text: string[]) => html`
          <sp-link
            target="_blank"
            data-id="PrivacyPolicyInfoShare"
            quiet
            href=${LINKS.privacyPolicyInfoShare()}
            >${text}</sp-link
          >
        `,
        'privacy-policy-link': (text: string[]) => html`
          <sp-link
            target="_blank"
            data-id="PrivacyPolicy"
            quiet
            href=${LINKS.privacyPolicy()}
            >${text}</sp-link
          >
        `,
        'marketing-email-link': (text: string[]) => html`
          <sp-link
            target="_blank"
            data-id="MarketingEmail"
            quiet
            href=${LINKS.marketingEmail()}
          >
            ${text}
          </sp-link>
        `,
      }
    )}
  </div>
`;
