import Cookies from 'js-cookie';
import create, { StateCreator } from 'zustand/vanilla';

import { calculateAge, getBirthDateFromMonthAndYear } from './helpers';

export const COOKIE = {
  coppaCookieName: 'adobeidp_ccd',
  coppaCookieDomain: '.services.adobe.com',
};

const COPPA_AGE = 13;

export interface CookiesState {
  isCoppaCompliant: () => boolean;
  checkCoppaCompliance: (month: number, year: number) => boolean;
  storeCoppaCookie: () => void;
}

function storeCoppaCookie() {
  Cookies.set(COOKIE.coppaCookieName, '1', {
    expires: 1,
  });
}

export const createCookiesStore: StateCreator<CookiesState> = (set, get) => ({
  isCoppaCompliant: () => !Cookies.get(COOKIE.coppaCookieName),
  checkCoppaCompliance: (month: number, year: number) => {
    const userBirthDate = getBirthDateFromMonthAndYear(month, year);
    const userAge = calculateAge(userBirthDate);

    if (COPPA_AGE > userAge) {
      storeCoppaCookie();
      return false;
    }

    return true;
  },
  storeCoppaCookie,
});

const store = create<CookiesState>(createCookiesStore);

export const getCookiesStore = () => store;
export const getCookiesState = () => getCookiesStore().getState();
