import { AppEvents } from '$scripts/events/events';
import { Outcome } from '$scripts/outcome';
import { RemainingOutcomes } from './helpers';
import {
  AuthCodeOutcome,
  AuthFailedOutcome,
  RedirectOutcome,
  TokenOutcome,
} from './outcomes';

type TokenOutcomeHandled<T> = RemainingOutcomes<T, TokenOutcome>;
type AuthCodeOutcomeHandled<T> = RemainingOutcomes<T, AuthCodeOutcome>;
type AuthFailedOutcomeHandled<T> = RemainingOutcomes<T, AuthFailedOutcome>;
type RedirectOutcomeHandled<T> = RemainingOutcomes<T, RedirectOutcome>;

type OutcomeHandler<T extends Outcome> = (events: AppEvents, outcome: T) => T;

function createOutcomeHandler<T extends OutcomeHandler<Outcome>>(
  handler: T
): T {
  return function (events: AppEvents, outcome: Outcome) {
    if (!outcome.isHandled) {
      return handler(events, outcome);
    } else {
      return outcome;
    }
  } as T;
}

export const defaultHandleToken = createOutcomeHandler(function <
  T extends Outcome
>(events: AppEvents, outcome: T) {
  if (outcome.of(TokenOutcome)) {
    const { token } = (outcome as TokenOutcome).getValue();
    events.sendToken(token);
    outcome.setHandled();
  }

  return outcome as TokenOutcomeHandled<T>;
});

export const defaultHandleAuthCode = createOutcomeHandler(function <
  T extends Outcome
>(events: AppEvents, outcome: T) {
  if (outcome.of(AuthCodeOutcome)) {
    const { code } = (outcome as AuthCodeOutcome).getValue();
    events.sendAuthCode(code);
    outcome.setHandled();
  }

  return outcome as AuthCodeOutcomeHandled<T>;
});

export const defaultHandleAuthFailed = createOutcomeHandler(function <
  T extends Outcome
>(events: AppEvents, outcome: T) {
  if (outcome.of(AuthFailedOutcome)) {
    const { reason, fallbackUrl } = (outcome as AuthFailedOutcome).getValue();
    events.sendAuthFailed({ reason, fallbackUrl });
    outcome.setHandled();
  }

  return outcome as AuthFailedOutcomeHandled<T>;
});

export const defaultHandleRedirect = createOutcomeHandler(function <
  T extends Outcome
>(events: AppEvents, outcome: T) {
  if (outcome.of(RedirectOutcome)) {
    const { url } = (outcome as RedirectOutcome).getValue();
    events.redirect(url);
    outcome.setHandled();
  }

  return outcome as RedirectOutcomeHandled<T>;
});
