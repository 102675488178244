import {
  consumeImplicitJumpToken,
  socialHeadlessCall,
} from '$api/headless-api';
import { completeAccountFlow } from '$flows/complete-account/complete-account';
import {
  CloseModalOutcome,
  UserCreatedOutcome,
} from '$pages/variants/complete-account/controller';
import { PageOutcome, largeButtons } from '$pages/variants/large-buttons';
import { InitialState as LargeButtonsState } from '$pages/variants/large-buttons/state';
import { AsyncWrapper } from '$scripts/async-wrapper';
import { AppEvents } from '$scripts/events/events';
import { Provider, PROVIDERS } from '$shared/types/social-provider';
import { getConfigurationsState } from '$store/configurations-store';
import { AdobeCreateAccountTrait } from '$traits/adobe-create-account';
import { AdobeSignInTrait } from '$traits/adobe-sign-in';
import { CompleteAccountRequiredOutcome } from '$traits/outcomes';
import { SocialSignInTrait } from '$traits/social-sign-in';
import { envs } from '@sentry/shared';

import { endOfFlow } from '../../helpers/end-of-flow';
import { FlowConfig } from './schema';
import { ENVIRONMENTS } from '@sentry/shared/dist/environments';

/**
 *  Example of preload:
 *   const pages = [largeButtons, errorPage].reduce(
        (acc, page) => acc.then(() => page.load()),
        Promise.resolve()
      );
 * 
 */

export async function largeButtonsFlow(
  events: AppEvents,
  config: FlowConfig
): Promise<any> {
  const { fetchAndCacheSocialProviders } = getConfigurationsState();
  const { hasPasskeys } = config.features;

  const providers = AsyncWrapper.from(
    fetchAndCacheSocialProviders().then((socialProviders: Provider[]) =>
      socialProviders.concat(hasPasskeys ? [PROVIDERS.passkey] : [])
    )
  );

  const pageState: LargeButtonsState = {
    async: { providers },
    maxSocialProviders: 3,
  };

  let outcome = await largeButtons.create(pageState);

  outcome = AdobeSignInTrait.withPopupOrRedirect.handle(
    events,
    outcome
  ) as PageOutcome;
  outcome = AdobeCreateAccountTrait.withPopupOrRedirect.handle(
    events,
    outcome
  ) as PageOutcome;
  outcome = SocialSignInTrait.withPopupOrRedirect.handle(
    events,
    outcome
  ) as PageOutcome;

  // TODO: Investigate and fix type issue
  if (outcome.of(CompleteAccountRequiredOutcome)) {
    const { socialProvider: provider, client_id } = outcome.getValue();

    outcome = await completeAccountFlow(
      events,
      {
        consentProfile: config.consentProfile,
      },
      outcome.getValue()
    );
    if (outcome.of(UserCreatedOutcome)) {
      // // Use token to sign in
      const { token } = outcome.getValue();
      const responseType =
        envs.getAppEnv() !== ENVIRONMENTS.prod ? 'valid_user' : 'implicit_jump';

      const headless = await socialHeadlessCall(
        token,
        provider,
        client_id,
        responseType
      );

      // // Do token exchange
      const res = await consumeImplicitJumpToken(headless.token, client_id);

      // // Send event to client
      events.sendToken(res.access_token);

      // // End of flow
      outcome.setHandled();
      events.closeModal();

      return await largeButtonsFlow(events, config);
    }

    if (outcome.of(CloseModalOutcome)) {
      return await largeButtonsFlow(events, config);
    }
  }

  return endOfFlow(events, outcome);
}
