/*
Copyright 2022 Adobe. All rights reserved.
This file is licensed to you under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License. You may obtain a copy
of the License at http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software distributed under
the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR REPRESENTATIONS
OF ANY KIND, either express or implied. See the License for the specific language
governing permissions and limitations under the License.
*/
import { css } from '@spectrum-web-components/base';
const styles = css`
.spectrum-Body--sizeXXXL{font-size:var(
--spectrum-body-xxxl-text-size,var(--spectrum-global-dimension-font-size-600)
);font-style:var(
--spectrum-body-xxxl-text-font-style,var(--spectrum-global-font-style-regular)
);font-weight:var(
--spectrum-body-xxxl-text-font-weight,var(--spectrum-alias-body-text-font-weight)
);letter-spacing:var(
--spectrum-body-xxxl-text-letter-spacing,var(--spectrum-global-font-letter-spacing-none)
);line-height:var(
--spectrum-body-xxxl-text-line-height,var(--spectrum-alias-body-text-line-height)
);margin-bottom:0;margin-top:0;text-transform:var(--spectrum-body-xxxl-text-transform,none)}.spectrum-Body--sizeXXL{font-size:var(
--spectrum-body-xxl-text-size,var(--spectrum-global-dimension-font-size-500)
);font-style:var(
--spectrum-body-xxl-text-font-style,var(--spectrum-global-font-style-regular)
);font-weight:var(
--spectrum-body-xxl-text-font-weight,var(--spectrum-alias-body-text-font-weight)
);letter-spacing:var(
--spectrum-body-xxl-text-letter-spacing,var(--spectrum-global-font-letter-spacing-none)
);line-height:var(
--spectrum-body-xxl-text-line-height,var(--spectrum-alias-body-text-line-height)
);margin-bottom:0;margin-top:0;text-transform:var(--spectrum-body-xxl-text-transform,none)}.spectrum-Body--sizeXL{font-size:var(
--spectrum-body-xl-text-size,var(--spectrum-global-dimension-font-size-400)
);font-style:var(
--spectrum-body-xl-text-font-style,var(--spectrum-global-font-style-regular)
);font-weight:var(
--spectrum-body-xl-text-font-weight,var(--spectrum-alias-body-text-font-weight)
);letter-spacing:var(
--spectrum-body-xl-text-letter-spacing,var(--spectrum-global-font-letter-spacing-none)
);line-height:var(
--spectrum-body-xl-text-line-height,var(--spectrum-alias-body-text-line-height)
);margin-bottom:0;margin-top:0;text-transform:var(--spectrum-body-xl-text-transform,none)}.spectrum-Body--sizeL{font-size:var(
--spectrum-body-l-text-size,var(--spectrum-global-dimension-font-size-300)
);font-style:var(
--spectrum-body-l-text-font-style,var(--spectrum-global-font-style-regular)
);font-weight:var(
--spectrum-body-l-text-font-weight,var(--spectrum-alias-body-text-font-weight)
);letter-spacing:var(
--spectrum-body-l-text-letter-spacing,var(--spectrum-global-font-letter-spacing-none)
);line-height:var(
--spectrum-body-l-text-line-height,var(--spectrum-alias-body-text-line-height)
);margin-bottom:0;margin-top:0;text-transform:var(--spectrum-body-l-text-transform,none)}.spectrum-Body--sizeM{font-size:var(
--spectrum-body-m-text-size,var(--spectrum-global-dimension-font-size-200)
);font-style:var(
--spectrum-body-m-text-font-style,var(--spectrum-global-font-style-regular)
);font-weight:var(
--spectrum-body-m-text-font-weight,var(--spectrum-alias-body-text-font-weight)
);letter-spacing:var(
--spectrum-body-m-text-letter-spacing,var(--spectrum-global-font-letter-spacing-none)
);line-height:var(
--spectrum-body-m-text-line-height,var(--spectrum-alias-body-text-line-height)
);margin-bottom:0;margin-top:0;text-transform:var(--spectrum-body-m-text-transform,none)}.spectrum-Body--sizeS{font-size:var(
--spectrum-body-s-text-size,var(--spectrum-global-dimension-font-size-100)
);font-style:var(
--spectrum-body-s-text-font-style,var(--spectrum-global-font-style-regular)
);font-weight:var(
--spectrum-body-s-text-font-weight,var(--spectrum-alias-body-text-font-weight)
);letter-spacing:var(
--spectrum-body-s-text-letter-spacing,var(--spectrum-global-font-letter-spacing-none)
);line-height:var(
--spectrum-body-s-text-line-height,var(--spectrum-alias-body-text-line-height)
);margin-bottom:0;margin-top:0;text-transform:var(--spectrum-body-s-text-transform,none)}.spectrum-Body--sizeXS{font-size:var(
--spectrum-body-xs-text-size,var(--spectrum-global-dimension-font-size-75)
);font-style:var(
--spectrum-body-xs-text-font-style,var(--spectrum-global-font-style-regular)
);font-weight:var(
--spectrum-body-xs-text-font-weight,var(--spectrum-alias-body-text-font-weight)
);letter-spacing:var(
--spectrum-body-xs-text-letter-spacing,var(--spectrum-global-font-letter-spacing-none)
);line-height:var(
--spectrum-body-xs-text-line-height,var(--spectrum-alias-body-text-line-height)
);margin-bottom:0;margin-top:0;text-transform:var(--spectrum-body-xs-text-transform,none)}.spectrum-Body{font-family:var(
--spectrum-body-m-text-font-family,var(--spectrum-alias-body-text-font-family)
)}.spectrum-Body .spectrum-Body-strong,.spectrum-Body strong{font-weight:var(
--spectrum-body-m-strong-text-font-weight,var(--spectrum-global-font-weight-bold)
)}.spectrum-Body .spectrum-Body-emphasized,.spectrum-Body em{font-style:var(
--spectrum-body-m-emphasized-text-font-style,var(--spectrum-global-font-style-italic)
)}.spectrum-Body--serif{font-family:var(
--spectrum-body-m-serif-text-font-family,var(--spectrum-alias-serif-text-font-family)
)}.spectrum-Body--sizeXXXL{color:var(
--spectrum-body-xxxl-text-color,var(--spectrum-alias-text-color)
)}.spectrum-Body--sizeXXL{color:var(
--spectrum-body-xxl-text-color,var(--spectrum-alias-text-color)
)}.spectrum-Body--sizeXL{color:var(
--spectrum-body-xl-text-color,var(--spectrum-alias-text-color)
)}.spectrum-Body--sizeL{color:var(
--spectrum-body-l-text-color,var(--spectrum-alias-text-color)
)}.spectrum-Body--sizeM{color:var(
--spectrum-body-m-text-color,var(--spectrum-alias-text-color)
)}.spectrum-Body--sizeS{color:var(
--spectrum-body-s-text-color,var(--spectrum-alias-text-color)
)}.spectrum-Body--sizeXS{color:var(
--spectrum-body-xs-text-color,var(--spectrum-alias-text-color)
)}.spectrum-Body{color:var(
--spectrum-body-m-text-color,var(--spectrum-alias-text-color)
)}
`;
export default styles;