import { HasOutcome } from '$scripts/base-controller';
import { AppEvents } from '$scripts/events/events';
import { Outcome } from '$scripts/outcome';
import { getApplicationState } from '$store/application-store';
import { GetOutcomes, RemainingOutcomes } from '$traits/helpers';
import {
  outcomeAction as createAccountWithPopupAction,
  handleOutcome as handleCreateAccountWithPopup,
} from './with-popup';
import {
  outcomeAction as createAccountWithRedirectAction,
  handleOutcome as handleCreateAccountWithRedirect,
} from './with-redirect';

interface ActionDependencies {
  getApplicationState: typeof getApplicationState;
}

interface CombinedOutcomeAction {
  createAccountWithPopupAction: typeof createAccountWithPopupAction;
  createAccountWithRedirectAction: typeof createAccountWithRedirectAction;
}

function createAction(
  { getApplicationState }: ActionDependencies,
  {
    createAccountWithPopupAction,
    createAccountWithRedirectAction,
  }: CombinedOutcomeAction
) {
  return function <TBase extends HasOutcome<any>>(base: TBase) {
    const createAccountWithPopup = createAccountWithPopupAction(base);
    const createAccountWithRedirect = createAccountWithRedirectAction(base);
    return async function action() {
      const { config } = await getApplicationState();

      return config.popup
        ? createAccountWithPopup()
        : createAccountWithRedirect();
    };
  };
}

export const outcomeAction = createAction(
  { getApplicationState },
  { createAccountWithPopupAction, createAccountWithRedirectAction }
);

export type TraitOutcome = GetOutcomes<typeof outcomeAction>;

export function handleOutcome<T extends Outcome>(
  events: AppEvents,
  outcome: T
) {
  outcome = handleCreateAccountWithPopup(events, outcome);
  outcome = handleCreateAccountWithRedirect(events, outcome);

  return outcome as RemainingOutcomes<T, TraitOutcome>;
}

export const trait = {
  action: outcomeAction,
  handle: handleOutcome,
};
