import { i18n } from '$locales/helpers';

export const monthsStrings: {
  [id: string]: () => string;
} = {
  0: () => i18n().formatMessage({ id: 'january', defaultMessage: 'January' }),
  1: () => i18n().formatMessage({ id: 'february', defaultMessage: 'February' }),
  2: () => i18n().formatMessage({ id: 'march', defaultMessage: 'March' }),
  3: () => i18n().formatMessage({ id: 'april', defaultMessage: 'April' }),
  4: () => i18n().formatMessage({ id: 'may', defaultMessage: 'May' }),
  5: () => i18n().formatMessage({ id: 'june', defaultMessage: 'June' }),
  6: () => i18n().formatMessage({ id: 'july', defaultMessage: 'July' }),
  7: () => i18n().formatMessage({ id: 'august', defaultMessage: 'August' }),
  8: () =>
    i18n().formatMessage({ id: 'september', defaultMessage: 'September' }),
  9: () => i18n().formatMessage({ id: 'october', defaultMessage: 'October' }),
  10: () =>
    i18n().formatMessage({ id: 'november', defaultMessage: 'November' }),
  11: () =>
    i18n().formatMessage({ id: 'december', defaultMessage: 'December' }),
};

export type LocalisedMonthObject = {
  id: string;
  value: () => string;
};

export const getLocalisedMonths = () =>
  Object.entries(monthsStrings).map(([key, val]) => ({
    id: key,
    value: val,
  }));
