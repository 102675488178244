import { SocialProvider } from '$shared/types/social-provider';
import { getImsEndpoint, getAdobeidServicesEndpoint } from '.';
import { PATHNAMES } from './constants';

export async function socialHeadlessCall(
  federatedToken: string,
  socialProvider: SocialProvider,
  client_id: string,
  response_type: string = 'implicit_jump'
) {
  const imsEndpoint = await getImsEndpoint();
  return await imsEndpoint
    .url(PATHNAMES.socialNativeHeadless)
    .query({
      provider_id: socialProvider,
      fed_token: federatedToken,
      client_id,
      scope: 'openid',
      response_type,
    })
    .post()
    .json(r => r);
}

export async function consumeImplicitJumpToken(
  token: string,
  client_id: string
) {
  const authServicesEndpoint = await getAdobeidServicesEndpoint();

  return await authServicesEndpoint
    .url(`${PATHNAMES.IJT}/${token}`)
    .query({
      client_id,
    })
    .options({
      credentials: 'include',
      withCredentials: true,
    })
    .get()
    .json(r => r);
}
