import '@spectrum-web-components/link/sp-link';
import '@spectrum-web-components/checkbox/sp-checkbox';

import { LitElement, PropertyValueMap, ReactiveElement, css, html } from 'lit';
import { customElement, property, queryAsync, state } from 'lit/decorators';

import { CountryId } from '$shared/types/countries';
import { flex } from '$shared/mixins/flex';

import {
  CONSENT_VALUES,
  Consent,
  ConsentProfile,
} from '$shared/types/marketing-consent';
import {
  DefaultTypesOfConsent,
  MARKETING_CONSENT_DEFAULT,
  getCountryConsentType,
} from './helpers';
import { MarketingConsentChangeEvent } from './types';
import {
  getExplicitOrSoftConsentElement,
  getImplicitConsentElement,
} from './policies';

@customElement('marketing-consent')
export class MarketingConsent extends LitElement {
  static styles = css`
    .marketing-consent--container {
      ${flex().direction('column').gap(100).build()}
      color: var(--spectrum-gray-800);
      font-size: 12px;
    }

    .implicit-consent ul {
      margin: 0;
      padding: 0 0 0 15px;
    }

    .consent-checkbox {
      color: inherit;
    }
  `;

  @property()
  country: CountryId = MARKETING_CONSENT_DEFAULT.countryId;

  @property()
  consentProfile: ConsentProfile = MARKETING_CONSENT_DEFAULT.consentProfile;

  @property()
  hasUserConsented: boolean = MARKETING_CONSENT_DEFAULT.hasUserConsented;
  @property()
  setUserConsent: (value: boolean) => void = () => {};

  @state()
  private _consentType: Consent = getCountryConsentType(
    this.country,
    this.consentProfile
  );

  @property()
  canUserConsent: boolean = true;

  protected updated(
    _changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>
  ): void {
    if (_changedProperties.has('country')) {
      const newConsentType: Consent = getCountryConsentType(
        this.country,
        this.consentProfile
      );

      this._consentType = newConsentType;

      this.setUserConsent(DefaultTypesOfConsent[newConsentType]);
    }
  }

  @queryAsync('.marketing-consent--container')
  getMarketingConsentElement?: Promise<ReactiveElement>;

  render() {
    this.getMarketingConsentElement?.then(
      (marketingConsentElement: ReactiveElement) => {
        const marketingConsentChangeEvent: MarketingConsentChangeEvent =
          new CustomEvent('marketing-consent-change', {
            bubbles: true,
            composed: true,
            detail: {
              html: marketingConsentElement?.innerHTML,
            },
          });

        this.dispatchEvent(marketingConsentChangeEvent);
      }
    );

    if (this._consentType === CONSENT_VALUES.implicit) {
      return getImplicitConsentElement(this.canUserConsent);
    }

    return getExplicitOrSoftConsentElement(
      this._consentType,
      this.canUserConsent,
      this.hasUserConsented,
      this.setUserConsent,
      this.dispatchEvent
    );
  }
}
