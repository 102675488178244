import { z } from 'zod';

import { COUNTRY_LIST } from '$locales/constants/countries';
import { UnionToTuple } from './helpers';

type AllowedCountries = UnionToTuple<keyof typeof COUNTRY_LIST>;

const countries = Object.keys(COUNTRY_LIST);
const CountryIdSchema = z.enum(countries as AllowedCountries);

const CountrySchema = z.object({
  id: CountryIdSchema,
  policies: z.object({
    ageOfConsent: z.number(),
  }),
});

export const NearCountrySchema = z
  .object({
    isoCode: CountryIdSchema,
  })
  .required()
  .transform(nearCountry => nearCountry.isoCode);

const verifyFetchCountriesResponse = z.array(CountrySchema);
const verifyFetchNearCountryResponse = NearCountrySchema;

type CountryId = z.infer<typeof CountryIdSchema>;
type Country = z.infer<typeof CountrySchema>;

export {
  Country,
  CountryId,
  verifyFetchCountriesResponse,
  verifyFetchNearCountryResponse,
};
