import { LitElement, css, html } from 'lit';
import { customElement, property } from 'lit/decorators';

import '@spectrum-web-components/textfield/sp-textfield';
import '@spectrum-web-components/help-text/sp-help-text';

import { labelStyling } from '$shared/mixins/label';

@customElement('text-field')
export class TextField extends LitElement {
  static styles = css`
    sp-field-label {
      ${labelStyling()}
    }

    .error-message-margin-bottom {
      margin-bottom: 24.5px;
    }
  `;

  @property()
  id: string = '';

  @property()
  initialValue: string = '';

  @property()
  isValid: boolean | null = null;

  @property()
  warningMessage: string = '';

  render() {
    return html`<div
      class=${`textfield-container ${
        this.isValid === false ? '' : 'error-message-margin-bottom'
      }`}
    >
      <sp-field-label for=${this.id}>
        <slot name="label" class="label-slot"></slot>
      </sp-field-label>
      <sp-textfield
        ?valid=${this.isValid === null ? null : this.isValid}
        ?invalid=${this.isValid === null ? null : !this.isValid}
        @input=${(e: any) => {
          this.dispatchEvent(
            new CustomEvent('textfield-input', {
              bubbles: true,
              composed: true,
              detail: {
                value: e.target.value,
              },
            })
          );
        }}
        value=${this.initialValue}
        @change=${(e: any) => {
          this.dispatchEvent(
            new CustomEvent('textfield-change', {
              bubbles: true,
              composed: true,
              detail: {
                value: e.target.value,
              },
            })
          );
        }}
        id=${this.id}
        quiet
      >
        <sp-help-text slot="negative-help-text">
          ${this.warningMessage}
        </sp-help-text>
      </sp-textfield>
    </div>`;
  }
}
