/*
Copyright 2022 Adobe. All rights reserved.
This file is licensed to you under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License. You may obtain a copy
of the License at http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software distributed under
the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR REPRESENTATIONS
OF ANY KIND, either express or implied. See the License for the specific language
governing permissions and limitations under the License.
*/
import { css } from '@spectrum-web-components/base';
const styles = css`
.spectrum-Heading--sizeXXXL{font-size:var(
--spectrum-heading-xxxl-text-size,var(--spectrum-alias-heading-xxxl-text-size)
);font-style:var(
--spectrum-heading-xxxl-text-font-style,var(--spectrum-global-font-style-regular)
);font-weight:var(
--spectrum-heading-xxxl-text-font-weight,var(--spectrum-alias-heading-text-font-weight-regular)
);letter-spacing:var(
--spectrum-heading-xxxl-text-letter-spacing,var(--spectrum-global-font-letter-spacing-none)
);line-height:var(
--spectrum-heading-xxxl-text-line-height,var(--spectrum-alias-heading-text-line-height)
);margin-bottom:0;margin-top:0;text-transform:var(--spectrum-heading-xxxl-text-transform,none)}.spectrum-Heading--sizeXXL{font-size:var(
--spectrum-heading-xxl-text-size,var(--spectrum-alias-heading-xxl-text-size)
);font-style:var(
--spectrum-heading-xxl-text-font-style,var(--spectrum-global-font-style-regular)
);font-weight:var(
--spectrum-heading-xxl-text-font-weight,var(--spectrum-alias-heading-text-font-weight-regular)
);letter-spacing:var(
--spectrum-heading-xxl-text-letter-spacing,var(--spectrum-global-font-letter-spacing-none)
);line-height:var(
--spectrum-heading-xxl-text-line-height,var(--spectrum-alias-heading-text-line-height)
);margin-bottom:0;margin-top:0;text-transform:var(--spectrum-heading-xxl-text-transform,none)}.spectrum-Heading--sizeXL{font-size:var(
--spectrum-heading-xl-text-size,var(--spectrum-alias-heading-xl-text-size)
);font-style:var(
--spectrum-heading-xl-text-font-style,var(--spectrum-global-font-style-regular)
);font-weight:var(
--spectrum-heading-xl-text-font-weight,var(--spectrum-alias-heading-text-font-weight-regular)
);letter-spacing:var(
--spectrum-heading-xl-text-letter-spacing,var(--spectrum-global-font-letter-spacing-none)
);line-height:var(
--spectrum-heading-xl-text-line-height,var(--spectrum-alias-heading-text-line-height)
);margin-bottom:0;margin-top:0;text-transform:var(--spectrum-heading-xl-text-transform,none)}.spectrum-Heading--sizeL{font-size:var(
--spectrum-heading-l-text-size,var(--spectrum-alias-heading-l-text-size)
);font-style:var(
--spectrum-heading-l-text-font-style,var(--spectrum-global-font-style-regular)
);font-weight:var(
--spectrum-heading-l-text-font-weight,var(--spectrum-alias-heading-text-font-weight-regular)
);letter-spacing:var(
--spectrum-heading-l-text-letter-spacing,var(--spectrum-global-font-letter-spacing-none)
);line-height:var(
--spectrum-heading-l-text-line-height,var(--spectrum-alias-heading-text-line-height)
);margin-bottom:0;margin-top:0;text-transform:var(--spectrum-heading-l-text-transform,none)}.spectrum-Heading--sizeM{font-size:var(
--spectrum-heading-m-text-size,var(--spectrum-alias-heading-m-text-size)
);font-style:var(
--spectrum-heading-m-text-font-style,var(--spectrum-global-font-style-regular)
);font-weight:var(
--spectrum-heading-m-text-font-weight,var(--spectrum-alias-heading-text-font-weight-regular)
);letter-spacing:var(
--spectrum-heading-m-text-letter-spacing,var(--spectrum-global-font-letter-spacing-none)
);line-height:var(
--spectrum-heading-m-text-line-height,var(--spectrum-alias-heading-text-line-height)
);margin-bottom:0;margin-top:0;text-transform:var(--spectrum-heading-m-text-transform,none)}.spectrum-Heading--sizeS{font-size:var(
--spectrum-heading-s-text-size,var(--spectrum-alias-heading-s-text-size)
);font-style:var(
--spectrum-heading-s-text-font-style,var(--spectrum-global-font-style-regular)
);font-weight:var(
--spectrum-heading-s-text-font-weight,var(--spectrum-alias-heading-text-font-weight-regular)
);letter-spacing:var(
--spectrum-heading-s-text-letter-spacing,var(--spectrum-global-font-letter-spacing-none)
);line-height:var(
--spectrum-heading-s-text-line-height,var(--spectrum-alias-heading-text-line-height)
);margin-bottom:0;margin-top:0;text-transform:var(--spectrum-heading-s-text-transform,none)}.spectrum-Heading--sizeXS{font-size:var(
--spectrum-heading-xs-text-size,var(--spectrum-alias-heading-xs-text-size)
);font-style:var(
--spectrum-heading-xs-text-font-style,var(--spectrum-global-font-style-regular)
);font-weight:var(
--spectrum-heading-xs-text-font-weight,var(--spectrum-alias-heading-text-font-weight-regular)
);letter-spacing:var(
--spectrum-heading-xs-text-letter-spacing,var(--spectrum-global-font-letter-spacing-none)
);line-height:var(
--spectrum-heading-xs-text-line-height,var(--spectrum-alias-heading-text-line-height)
);margin-bottom:0;margin-top:0;text-transform:var(--spectrum-heading-xs-text-transform,none)}.spectrum-Heading--sizeXXS{font-size:var(
--spectrum-heading-xxs-text-size,var(--spectrum-alias-heading-xxs-text-size)
);font-style:var(
--spectrum-heading-xxs-text-font-style,var(--spectrum-global-font-style-regular)
);font-weight:var(
--spectrum-heading-xxs-text-font-weight,var(--spectrum-alias-heading-text-font-weight-regular)
);letter-spacing:var(
--spectrum-heading-xxs-text-letter-spacing,var(--spectrum-global-font-letter-spacing-none)
);line-height:var(
--spectrum-heading-xxs-text-line-height,var(--spectrum-alias-heading-text-line-height)
);margin-bottom:0;margin-top:0;text-transform:var(--spectrum-heading-xxs-text-transform,none)}.spectrum-Heading{font-family:var(
--spectrum-heading-m-text-font-family,var(--spectrum-alias-body-text-font-family)
);font-weight:var(
--spectrum-heading-m-text-font-weight,var(--spectrum-alias-heading-text-font-weight-regular)
)}.spectrum-Heading .spectrum-Heading-emphasized,.spectrum-Heading em{font-style:var(
--spectrum-heading-m-emphasized-text-font-style,var(--spectrum-global-font-style-italic)
)}.spectrum-Heading .spectrum-Heading-strong,.spectrum-Heading strong{font-weight:var(
--spectrum-heading-m-strong-text-font-weight,var(--spectrum-alias-heading-text-font-weight-regular-strong)
)}.spectrum-Heading--serif{font-family:var(
--spectrum-body-m-serif-text-font-family,var(--spectrum-alias-serif-text-font-family)
)}.spectrum-Heading--heavy{font-weight:var(
--spectrum-heading-m-heavy-text-font-weight,var(--spectrum-alias-heading-text-font-weight-heavy)
)}.spectrum-Heading--heavy .spectrum-Heading-emphasized,.spectrum-Heading--heavy em{font-style:var(
--spectrum-heading-m-heavy-emphasized-text-font-style,var(--spectrum-global-font-style-italic)
)}.spectrum-Heading--heavy .spectrum-Heading-strong,.spectrum-Heading--heavy strong{font-weight:var(
--spectrum-heading-m-heavy-strong-text-font-weight,var(--spectrum-alias-heading-text-font-weight-heavy-strong)
)}.spectrum-Heading--light{font-weight:var(
--spectrum-heading-m-light-emphasized-text-font-weight,var(--spectrum-alias-heading-text-font-weight-light)
)}.spectrum-Heading--light .spectrum-Heading-emphasized,.spectrum-Heading--light em{font-style:var(
--spectrum-heading-m-light-emphasized-text-font-style,var(--spectrum-global-font-style-italic)
)}.spectrum-Heading--light .spectrum-Heading-strong,.spectrum-Heading--light strong{font-weight:var(
--spectrum-heading-m-light-strong-text-font-weight,var(--spectrum-alias-heading-text-font-weight-light-strong)
)}.spectrum-Heading--sizeXXXL{color:var(
--spectrum-heading-xxxl-text-color,var(--spectrum-alias-heading-text-color)
)}.spectrum-Heading--sizeXXL{color:var(
--spectrum-heading-xxl-text-color,var(--spectrum-alias-heading-text-color)
)}.spectrum-Heading--sizeXL{color:var(
--spectrum-heading-xl-text-color,var(--spectrum-alias-heading-text-color)
)}.spectrum-Heading--sizeL{color:var(
--spectrum-heading-l-text-color,var(--spectrum-alias-heading-text-color)
)}.spectrum-Heading--sizeM{color:var(
--spectrum-heading-m-text-color,var(--spectrum-alias-heading-text-color)
)}.spectrum-Heading--sizeS{color:var(
--spectrum-heading-s-text-color,var(--spectrum-alias-heading-text-color)
)}.spectrum-Heading--sizeXS{color:var(
--spectrum-heading-xs-text-color,var(--spectrum-alias-heading-text-color)
)}.spectrum-Heading--sizeXXS{color:var(
--spectrum-heading-xxs-text-color,var(--spectrum-alias-heading-text-color)
)}.spectrum-Heading-sizeXXXL--light{color:var(
--spectrum-heading-xxxl-light-text-color,var(--spectrum-alias-heading-text-color)
)}.spectrum-Heading-sizeXXL--light{color:var(
--spectrum-heading-xxl-light-text-color,var(--spectrum-alias-heading-text-color)
)}.spectrum-Heading-sizeXL--light{color:var(
--spectrum-heading-xl-light-text-color,var(--spectrum-alias-heading-text-color)
)}.spectrum-Heading-sizeL--light{color:var(
--spectrum-heading-l-light-text-color,var(--spectrum-alias-heading-text-color)
)}.spectrum-Heading-sizeXXXL--heavy{color:var(
--spectrum-heading-xxxl-heavy-text-color,var(--spectrum-alias-heading-text-color)
)}.spectrum-Heading-sizeXXL--heavy{color:var(
--spectrum-heading-xxl-heavy-text-color,var(--spectrum-alias-heading-text-color)
)}.spectrum-Heading-sizeXL--heavy{color:var(
--spectrum-heading-xl-heavy-text-color,var(--spectrum-alias-heading-text-color)
)}.spectrum-Heading-sizeL--heavy{color:var(
--spectrum-heading-l-heavy-text-color,var(--spectrum-alias-heading-text-color)
)}.spectrum-Heading-sizeXXXL--heading{color:var(
--spectrum-heading-xxxl-text-color,var(--spectrum-alias-heading-text-color)
)}.spectrum-Heading-sizeXXL--heading{color:var(
--spectrum-heading-xxl-text-color,var(--spectrum-alias-heading-text-color)
)}.spectrum-Heading-sizeXL--heading{color:var(
--spectrum-heading-xl-text-color,var(--spectrum-alias-heading-text-color)
)}.spectrum-Heading-sizeL--heading{color:var(
--spectrum-heading-l-text-color,var(--spectrum-alias-heading-text-color)
)}
`;
export default styles;