import { html } from 'lit';
import { customElement, state } from 'lit/decorators';

import '$components/loading-wrapper';
import { SentryPage } from '$components/template';
import { getConfigurationsStore } from '$store/index';

@customElement('app-wrapper')
export class AppWrapper extends SentryPage<{}, {}> {
  @state()
  isLocaleLoading: Boolean = false;
  unsubIsLocaleLoading: Function = () => {};

  initializeIsLocaleLoading = () => {
    const store = getConfigurationsStore();
    const unsub = store.subscribe(
      state => (this.isLocaleLoading = state.isLocaleLoading)
    );

    this.isLocaleLoading = store.getState().isLocaleLoading;
    this.unsubIsLocaleLoading = unsub;
  };

  disconnectedCallback(): void {
    super.disconnectedCallback();
    this.unsubIsLocaleLoading();
  }

  firstUpdated() {
    this.initializeIsLocaleLoading();
  }

  render() {
    return html`
      <loading-wrapper ?displaySpinner=${this.isLocaleLoading} delay=${1500}>
        <slot></slot>
      </loading-wrapper>
    `;
  }
}
