import { CountryId } from '$shared/types/countries';
import {
  CONSENT_PROFILES,
  CONSENT_VALUES,
  Consent,
  ConsentProfile,
} from '$shared/types/marketing-consent';

const defaultMarketingConsentProfiles: { [key in ConsentProfile]: Consent } = {
  [CONSENT_PROFILES.adobeIdSignUp]: CONSENT_VALUES.explicit,
  [CONSENT_PROFILES.purchase]: CONSENT_VALUES.soft,
  [CONSENT_PROFILES.quoteRequest]: CONSENT_VALUES.explicit,
  [CONSENT_PROFILES.trial]: CONSENT_VALUES.soft,
  [CONSENT_PROFILES.free]: CONSENT_VALUES.soft,
};

const marketingConsentProfileOverrides: {
  [key in CountryId]?: { [key in ConsentProfile]?: Consent };
} = {
  AT: {
    [CONSENT_PROFILES.free]: CONSENT_VALUES.explicit,
  },
  AU: {
    [CONSENT_PROFILES.free]: CONSENT_VALUES.explicit,
    [CONSENT_PROFILES.purchase]: CONSENT_VALUES.explicit,
    [CONSENT_PROFILES.trial]: CONSENT_VALUES.explicit,
  },
  BE: {
    [CONSENT_PROFILES.free]: CONSENT_VALUES.explicit,
  },
  BG: {
    [CONSENT_PROFILES.free]: CONSENT_VALUES.explicit,
  },
  CA: {
    [CONSENT_PROFILES.free]: CONSENT_VALUES.explicit,
  },
  CH: {
    [CONSENT_PROFILES.free]: CONSENT_VALUES.explicit,
  },
  CY: {
    [CONSENT_PROFILES.free]: CONSENT_VALUES.explicit,
    [CONSENT_PROFILES.trial]: CONSENT_VALUES.explicit,
  },
  CZ: {
    [CONSENT_PROFILES.free]: CONSENT_VALUES.explicit,
  },
  DE: {
    [CONSENT_PROFILES.free]: CONSENT_VALUES.explicit,
  },
  DK: {
    [CONSENT_PROFILES.free]: CONSENT_VALUES.explicit,
    [CONSENT_PROFILES.trial]: CONSENT_VALUES.explicit,
  },
  EE: {
    [CONSENT_PROFILES.free]: CONSENT_VALUES.explicit,
  },
  ES: {
    [CONSENT_PROFILES.free]: CONSENT_VALUES.explicit,
  },
  FI: {
    [CONSENT_PROFILES.free]: CONSENT_VALUES.explicit,
  },
  FR: {
    [CONSENT_PROFILES.free]: CONSENT_VALUES.explicit,
    [CONSENT_PROFILES.trial]: CONSENT_VALUES.explicit,
  },
  GB: {
    [CONSENT_PROFILES.free]: CONSENT_VALUES.explicit,
  },
  GR: {
    [CONSENT_PROFILES.free]: CONSENT_VALUES.explicit,
  },
  HR: {
    [CONSENT_PROFILES.free]: CONSENT_VALUES.explicit,
    [CONSENT_PROFILES.trial]: CONSENT_VALUES.explicit,
  },
  HU: {
    [CONSENT_PROFILES.free]: CONSENT_VALUES.explicit,
    [CONSENT_PROFILES.trial]: CONSENT_VALUES.explicit,
  },
  IE: {
    [CONSENT_PROFILES.free]: CONSENT_VALUES.explicit,
  },
  IN: {
    [CONSENT_PROFILES.adobeIdSignUp]: CONSENT_VALUES.implicit,
    [CONSENT_PROFILES.free]: CONSENT_VALUES.implicit,
    [CONSENT_PROFILES.purchase]: CONSENT_VALUES.implicit,
    [CONSENT_PROFILES.quoteRequest]: CONSENT_VALUES.implicit,
    [CONSENT_PROFILES.trial]: CONSENT_VALUES.implicit,
  },
  IS: {
    [CONSENT_PROFILES.free]: CONSENT_VALUES.explicit,
    [CONSENT_PROFILES.trial]: CONSENT_VALUES.explicit,
  },
  IT: {
    [CONSENT_PROFILES.free]: CONSENT_VALUES.explicit,
  },
  JP: {
    [CONSENT_PROFILES.adobeIdSignUp]: CONSENT_VALUES.implicit,
    [CONSENT_PROFILES.free]: CONSENT_VALUES.implicit,
    [CONSENT_PROFILES.purchase]: CONSENT_VALUES.implicit,
    [CONSENT_PROFILES.quoteRequest]: CONSENT_VALUES.implicit,
    [CONSENT_PROFILES.trial]: CONSENT_VALUES.implicit,
  },
  KR: {
    [CONSENT_PROFILES.free]: CONSENT_VALUES.explicit,
    [CONSENT_PROFILES.purchase]: CONSENT_VALUES.explicit,
    [CONSENT_PROFILES.trial]: CONSENT_VALUES.explicit,
  },
  LI: {
    [CONSENT_PROFILES.free]: CONSENT_VALUES.explicit,
    [CONSENT_PROFILES.trial]: CONSENT_VALUES.explicit,
  },
  LT: {
    [CONSENT_PROFILES.free]: CONSENT_VALUES.explicit,
  },
  LU: {
    [CONSENT_PROFILES.free]: CONSENT_VALUES.explicit,
    [CONSENT_PROFILES.trial]: CONSENT_VALUES.explicit,
  },
  LV: {
    [CONSENT_PROFILES.free]: CONSENT_VALUES.explicit,
  },
  MT: {
    [CONSENT_PROFILES.free]: CONSENT_VALUES.explicit,
  },
  NL: {
    [CONSENT_PROFILES.free]: CONSENT_VALUES.explicit,
    [CONSENT_PROFILES.trial]: CONSENT_VALUES.explicit,
  },
  NO: {
    [CONSENT_PROFILES.free]: CONSENT_VALUES.explicit,
    [CONSENT_PROFILES.trial]: CONSENT_VALUES.explicit,
  },
  PL: {
    [CONSENT_PROFILES.free]: CONSENT_VALUES.explicit,
    [CONSENT_PROFILES.trial]: CONSENT_VALUES.explicit,
  },
  PT: {
    [CONSENT_PROFILES.free]: CONSENT_VALUES.explicit,
  },
  RO: {
    [CONSENT_PROFILES.free]: CONSENT_VALUES.explicit,
    [CONSENT_PROFILES.trial]: CONSENT_VALUES.explicit,
  },
  RU: {
    [CONSENT_PROFILES.free]: CONSENT_VALUES.explicit,
    [CONSENT_PROFILES.purchase]: CONSENT_VALUES.explicit,
    [CONSENT_PROFILES.trial]: CONSENT_VALUES.explicit,
  },
  SE: {
    [CONSENT_PROFILES.free]: CONSENT_VALUES.explicit,
  },
  SG: {
    [CONSENT_PROFILES.free]: CONSENT_VALUES.explicit,
    [CONSENT_PROFILES.purchase]: CONSENT_VALUES.explicit,
    [CONSENT_PROFILES.trial]: CONSENT_VALUES.explicit,
  },
  SI: {
    [CONSENT_PROFILES.free]: CONSENT_VALUES.explicit,
    [CONSENT_PROFILES.trial]: CONSENT_VALUES.explicit,
  },
  SK: {
    [CONSENT_PROFILES.free]: CONSENT_VALUES.explicit,
    [CONSENT_PROFILES.trial]: CONSENT_VALUES.explicit,
  },
  US: {
    [CONSENT_PROFILES.adobeIdSignUp]: CONSENT_VALUES.implicit,
    [CONSENT_PROFILES.free]: CONSENT_VALUES.implicit,
    [CONSENT_PROFILES.purchase]: CONSENT_VALUES.implicit,
    [CONSENT_PROFILES.quoteRequest]: CONSENT_VALUES.implicit,
    [CONSENT_PROFILES.trial]: CONSENT_VALUES.implicit,
  },
};

export { defaultMarketingConsentProfiles, marketingConsentProfileOverrides };
