import { css, unsafeCSS } from 'lit';

export const flex = () => {
  // these are the default values
  let display = css`flex`;
  let justify = css`stretch`;
  let align = css`stretch`;
  let gap = css`0`;
  let direction = css`row`;

  return {
    inlineFlex: function () {
      display = css`inline-flex`;
      return this;
    },
    justify: function (input: string) {
      justify = css`
        ${unsafeCSS(input)}
      `;
      return this;
    },
    align: function (input: string) {
      align = css`
        ${unsafeCSS(input)}
      `;
      return this;
    },
    center: function () {
      justify = css`center`;
      align = css`center`;
      return this;
    },
    direction: function (input: string) {
      direction = css`
        ${unsafeCSS(input)}
      `;
      return this;
    },
    gap: function (input: number) {
      gap = css`
        var(--spacing-${input})
      `;
      return this;
    },
    build: () => css`
      display: ${display};
      justify-content: ${justify};
      align-items: ${align};
      flex-direction: ${direction};
      gap: ${gap};
    `,
  };
};
