import { getAnalyticsState } from '$store/index';
import { createInteractionEvent } from './events';

const _throw = (err: string) => {
  throw new Error(err);
};

export type eventNameOrFn = string | ((context: any, ...args: any[]) => string);
export type booleanOrUniqueFn = boolean | ((...args: any[]) => boolean);

function isFunction(
  customizer: eventNameOrFn
): customizer is (context: any, ...args: any[]) => string {
  return typeof customizer === 'function';
}

function isUniqueByFunction(
  uniqueBy: booleanOrUniqueFn
): uniqueBy is (...args: any[]) => boolean {
  return typeof uniqueBy === 'function';
}

export function InteractionEvent(
  customizer: eventNameOrFn,
  shouldBeUnique: booleanOrUniqueFn = false
): MethodDecorator & PropertyDecorator {
  return function (
    target: any,
    propertyKey: PropertyKey,
    descriptor?: PropertyDescriptor
  ): any {
    const isMethod = descriptor && descriptor.value;
    const sentEvents = new Set<string>();
    let originalFunction = isMethod ? descriptor!.value : target[propertyKey];

    function createDecoratedFunction(this: any, ...args: any[]): any {
      const { push } = getAnalyticsState();
      const finalEventName = isFunction(customizer)
        ? customizer.apply(this, [this, ...args])
        : customizer;

      if (
        (isUniqueByFunction(shouldBeUnique) &&
          !shouldBeUnique.apply(this, args)) ||
        !(shouldBeUnique && sentEvents.has(finalEventName))
      ) {
        push(createInteractionEvent({ eventName: finalEventName }));
        sentEvents.add(finalEventName);
      }

      return originalFunction.apply(this, args);
    }

    if (isMethod) {
      descriptor!.value = createDecoratedFunction;
      return descriptor;
    } else {
      Object.defineProperty(target, propertyKey, {
        get() {
          return createDecoratedFunction;
        },
        set(newValue) {
          return typeof newValue === 'function'
            ? (originalFunction = newValue)
            : _throw(
                'Cannot assign non-function value to a decorated property.'
              );
        },
        enumerable: true,
        configurable: true,
      });
    }
  };
}
