import { PopupBlockedError } from '$scripts/events/dispatchers';
import { AppEvents } from '$scripts/events/events';
import { CriticalOutcome, Outcome } from '$scripts/outcome';
import { queryState as qs } from '$scripts/query-state';
import { signInAdobePopup } from '$scripts/urls/handlers';
import { signInAdobeUrl } from '$scripts/urls/list';
import { AUTH_FAILED_REASON } from '$shared/types/auth-failed-reasons';
import { PROVIDERS } from '$shared/types/social-provider';
import { getApplicationState } from '$store/application-store';
import {
  defaultHandleAuthCode,
  defaultHandleAuthFailed,
  defaultHandleToken,
} from '$traits/handlers';
import {
  GetOutcomes,
  RemainingOutcomes,
  outcomeFunction,
} from '$traits/helpers';
import {
  AuthCodeOutcome,
  AuthFailedOutcome,
  TokenOutcome,
} from '$traits/outcomes';
import { convertError } from '@sentry/shared';

interface ActionDependencies {
  getApplicationState: typeof getApplicationState;
}

function createAction({ getApplicationState }: ActionDependencies) {
  return async function action() {
    const { events, eventListener, queryState } = await getApplicationState();
    events.sendProvider(PROVIDERS.adobe);

    try {
      if (queryState.response_type === 'code') {
        const {
          data: { code },
        } = await signInAdobePopup.withAuthCode(
          events,
          eventListener(),
          queryState
        );
        return new AuthCodeOutcome({ code });
      } else {
        const {
          data: { access_token },
        } = await signInAdobePopup.withAccessToken(
          events,
          eventListener(),
          queryState
        );
        return new TokenOutcome({ token: access_token });
      }
    } catch (e) {
      if (e instanceof PopupBlockedError) {
        const fallbackUrl = signInAdobeUrl(qs.toObject(queryState))();

        return new AuthFailedOutcome({
          reason: AUTH_FAILED_REASON.popupBlocked,
          fallbackUrl,
        });
      }

      const error = convertError(e);
      return new CriticalOutcome(error);
    }
  };
}

export const outcomeAction = outcomeFunction(
  createAction({ getApplicationState })
);

export type TraitOutcome = GetOutcomes<typeof outcomeAction>;

export function handleOutcome<T extends Outcome>(
  events: AppEvents,
  outcome: T
) {
  defaultHandleToken(events, outcome);
  defaultHandleAuthCode(events, outcome);
  defaultHandleAuthFailed(events, outcome);

  return outcome as RemainingOutcomes<T, TraitOutcome>;
}

export const trait = {
  action: outcomeAction,
  handle: handleOutcome,
};
