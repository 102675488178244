import create, { StateCreator } from 'zustand/vanilla';

import {
  fetchCountries,
  fetchNearCountry,
} from '../api/countries/countries-api';
import { Country, CountryId } from 'shared/types/countries';
import { LoadingState, asyncStoreHelper } from './helpers';

interface CountriesState {
  countries: Country[];
  nearCountryId: CountryId;
  nearCountryAgeOfConsent: number;
  fetchAndCacheCountries: () => Promise<Country[]>;
  fetchAndCacheNearCountry: () => Promise<CountryId | undefined>;
}

export const createCountriesStore: StateCreator<
  LoadingState<CountriesState>
> = () => ({
  loading: true,
  countries: [],
  nearCountryId: undefined,
  nearCountryAgeOfConsent: undefined,
});

const store = create<LoadingState<CountriesState>>(createCountriesStore);
export const getCountriesStore = () => store;
export const getCountriesState = async () => {
  if (getCountriesStore().getState().loading) {
    const [countries, nearCountry] = await Promise.all([
      fetchCountries(),
      fetchNearCountry(),
    ]);

    getCountriesStore().setState({
      countries,
      nearCountryId: nearCountry,
      nearCountryAgeOfConsent: countries.find(
        country => country.id === nearCountry
      )?.policies?.ageOfConsent,
      loading: false,
    });
  }

  return await asyncStoreHelper<CountriesState>(getCountriesStore());
};
