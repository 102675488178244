import { expressTheme } from '$pages/variants/express-theme';
import { InitialState as ExpressThemeState } from '$pages/variants/express-theme/state';
import { AppEvents } from '$scripts/events/events';
import { PROVIDERS } from '$shared/types/social-provider';
import { getConfigurationsState } from '$store/configurations-store';
import { AdobeSignInTrait } from '$traits/adobe-sign-in';
import { SocialSignInTrait } from '$traits/social-sign-in';
import { endOfFlow } from '../../helpers/end-of-flow';

export async function expressThemeFlow(events: AppEvents): Promise<any> {
  const { fetchAndCacheSocialProviders } = getConfigurationsState();
  const socialProviders = await fetchAndCacheSocialProviders();

  const expressPageState: ExpressThemeState = {
    socialProviders: [
      ...socialProviders.slice(0, 3),
      PROVIDERS.adobe,
      ...socialProviders.slice(3),
    ],
    maxSocialProviders: 4,
  };

  let outcome = await expressTheme.create(expressPageState);

  outcome = AdobeSignInTrait.withPopupOrRedirect.handle(events, outcome);
  outcome = SocialSignInTrait.withPopupOrRedirect.handle(events, outcome);

  return endOfFlow(events, outcome);
}
