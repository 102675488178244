import { LitElement, html } from 'lit';
import { customElement, property } from 'lit/decorators';
import { until } from 'lit/directives/until';

import { wait } from '$shared/helpers';

import '../progress-circle';

@customElement('loading-wrapper')
export class LoadingWrapper extends LitElement {
  @property({ type: Boolean })
  displaySpinner: boolean = false;

  @property()
  delay = 0;

  render() {
    return this.displaySpinner
      ? until(
          wait(this.delay).then(() => html`<progress-circle />`),
          html``
        )
      : html`<slot></slot>`;
  }
}
