import { LitElement } from 'lit';
import { property } from 'lit/decorators';

export class SentryPage<TController, TState> extends LitElement {
  @property({ type: Boolean })
  isLoading = false;

  @property({ type: Function })
  dispatcher = (param: any) => {};

  @property({ type: Object })
  controller: TController = {} as TController;

  @property({ type: Object })
  state: TState = null as TState;
}
