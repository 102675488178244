import { DefaultBodyType } from 'msw';

const unhandled = {
  ANALYTICS: '/d4d114c60e50',
  ASSETS: '/ee/irl1',
  LARGE_BUTTONS: '/large-buttons',
};

export function ignoreUnhandledRequests(request: any) {
  if (
    Object.values(unhandled).some(v => {
      const url = new URL(request.url);
      return url.pathname.startsWith(v);
    })
  ) {
    return;
  }

  console.warn('Unhandled: %s %s', request.method, request.url.href);
}
