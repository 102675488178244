import { html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators';

import bodyStyles from '@spectrum-web-components/styles/src/spectrum-body.css.js';
import typographyStyles from '@spectrum-web-components/styles/src/spectrum-typography.css.js';

@customElement('paragraph-tag')
export class ParagraphTag extends LitElement {
  @property()
  size = 'M';

  @property()
  className = '';

  @property()
  componentStyle = '';

  static styles = [bodyStyles, typographyStyles];

  render() {
    return html`
      <p
        class=${`spectrum-Body spectrum-Body--size${this.size}`}
        style=${this.componentStyle}
      >
        <slot></slot>
      </p>
    `;
  }
}
