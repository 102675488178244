import { trait as withPopup } from './with-popup';
import { trait as withPopupOrRedirect } from './with-popup-or-redirect';
import { trait as withRedirect } from './with-redirect';

export const AdobeSignInTrait = {
  withPopup,
  withRedirect,
  withPopupOrRedirect,
};

export interface HasAdobeSignInWithPopup {
  onSignIn: ReturnType<typeof AdobeSignInTrait.withPopup.action>;
}

export interface HasAdobeSignInWithRedirect {
  onSignIn: ReturnType<typeof AdobeSignInTrait.withRedirect.action>;
}

export interface HasAdobeSignInWithPopupOrRedirect {
  onSignIn: ReturnType<typeof AdobeSignInTrait.withPopupOrRedirect.action>;
}
