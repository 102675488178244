import { trait as withPopup } from './with-popup';
import { trait as withPopupOrRedirect } from './with-popup-or-redirect';
import { trait as withRedirect } from './with-redirect';

export const SocialSignInTrait = {
  withPopup,
  withRedirect,
  withPopupOrRedirect,
};

export interface HasSocialSignInWithPopup {
  onSocialProviderSignIn: ReturnType<typeof SocialSignInTrait.withPopup.action>;
}

export interface HasSocialSignInWithRedirect {
  onSocialProviderSignIn: ReturnType<
    typeof SocialSignInTrait.withRedirect.action
  >;
}

export interface HasSocialSignInWithPopupOrRedirect {
  onSocialProviderSignIn: ReturnType<
    typeof SocialSignInTrait.withPopupOrRedirect.action
  >;
}
