import { css, html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators';

@customElement('pending-button')
export class PendingButton extends LitElement {
  @property({ type: String })
  styling: string = '';

  static styles = [
    css`
      .pending-button-content-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    `,
  ];

  render() {
    return html`
      <sp-button style=${this.styling} disabled data-id="pending-button">
        <div class="pending-button-content-wrapper">
          <sp-progress-circle size="s" indeterminate></sp-progress-circle>
        </div>
      </sp-button>
    `;
  }
}
