import { QueryState, queryState as qs } from '$scripts/query-state';
import { getImsEndpoint } from '.';

import { PATHNAMES } from './constants';

export async function getQueryStateId(
  client_id: string,
  queryState: QueryState
): Promise<{ id: string; queryState: string }> {
  const imsEndpoint = await getImsEndpoint();

  const formattedQueryString = new URLSearchParams(qs.toString(queryState));

  return await imsEndpoint
    .url(PATHNAMES.queryState)
    .query({ id: client_id, queryState: formattedQueryString.toString() })
    .put()
    .json();
}
