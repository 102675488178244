import { createPage } from '$store/index';

import { PageOutcome } from './controller';
import { InitialProperties, PageState } from './state';

export * from './page';

const dimensions = {
  width: 510,
  height: 700,
};

const forceModal = true;

export const completeAccount = {
  load: () => import('./page-fetch'),
  create(initialState: InitialProperties) {
    return createPage<PageOutcome, Partial<PageState>>(this.load, {
      initialState,
      dimensions,
      forceModal,
      reason: 'complete-account',
    });
  },
};
