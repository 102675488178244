import { css, html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators';

import headingStyles from '@spectrum-web-components/styles/src/spectrum-heading.css.js';
import typographyStyles from '@spectrum-web-components/styles/src/spectrum-typography.css.js';

@customElement('heading-tag')
export class HeadingTag extends LitElement {
  @property()
  as = '';

  @property()
  size = 'M';

  static styles = [
    headingStyles,
    typographyStyles,
    css`
      :host {
        display: block;
      }

      .spectrum-Heading {
        color: var(--spectrum-gray-900);
      }
    `,
  ];

  getClasses() {
    return `spectrum-Heading spectrum-Heading--size${this.size}`;
  }

  render() {
    switch (this.as) {
      case 'h1':
        return html`
          <h1 class=${this.getClasses()}>
            <slot></slot>
          </h1>
        `;
      case 'h2':
        return html`
          <h2 class=${this.getClasses()}>
            <slot></slot>
          </h2>
        `;
      case 'h3':
        return html`
          <h3 class=${this.getClasses()}>
            <slot></slot>
          </h3>
        `;
      case 'h4':
        return html`
          <h4 class=${this.getClasses()}>
            <slot></slot>
          </h4>
        `;
      case 'h5':
        return html`
          <h5 class=${this.getClasses()}>
            <slot></slot>
          </h5>
        `;
      case 'h6':
        return html`
          <h6 class=${this.getClasses()}>
            <slot></slot>
          </h6>
        `;
      default:
        throw new Error(
          'The type used is either absent or an invalid heading type. Valid heading types are h1, h2, h3, h4, h5, h6.'
        );
    }
  }
}
