import { LitElement, css, html } from 'lit';
import { customElement, property } from 'lit/decorators';

import { getLocalisedMonths } from '$locales/constants/months';
import { i18n } from '$locales/helpers';

@customElement('date-of-birth')
export class DateOfBirth extends LitElement {
  static styles = [
    css`
      #dob-component-wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 24px 1fr;
        grid-template-areas:
          'dob-label .'
          'dob-container dob-container';
      }

      #dob-label {
        grid-area: dob-label;
      }

      #dob-container {
        grid-area: dob-container;
        align-items: start;

        display: grid;
        grid-template-columns: 1fr 1fr;
      }

      #yob-textfield {
        margin-top: 2px;
      }
    `,
  ];
  @property()
  handleMonthChange = (e: any) => {};

  @property()
  handleYearChange = (e: any) => {};

  @property()
  yobWarningMessage: string = '';

  @property()
  initialValue = {
    month: 0,
    year: '',
  };

  @property()
  isValid: boolean = true;

  render() {
    return html`<div id="dob-component-wrapper">
      <sp-field-label id="dob-label" for="dob-container">
        ${i18n().formatMessage({
          id: 'complete-account.label.dob',
          defaultMessage: 'Date of birth',
        })}
      </sp-field-label>

      <div id="dob-container">
        <sp-custom-select
          id="month-of-birth-picker"
          data-id="month-of-birth-picker"
          .width=${192}
          @sp-custom-select-change=${this.handleMonthChange}
          .data=${getLocalisedMonths()}
        >
          ${i18n().formatMessage({
            id: 'complete-account.label.month',
            defaultMessage: 'Month',
          })}
        </sp-custom-select>
        <text-field
          id="yob-textfield"
          data-id="yob-textfield"
          @textfield-input=${this.handleYearChange}
          .warningMessage=${this.yobWarningMessage}
          .initialValue=${this.initialValue?.year || ''}
          .isValid=${this.isValid}
        >
          <div slot="label">
            ${i18n().formatMessage({
              id: 'complete-account.label.year',
              defaultMessage: 'Year',
            })}
          </div>
        </text-field>
      </div>
    </div>`;
  }
}
