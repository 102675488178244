import { HasOutcome } from '$scripts/base-controller';
import { Outcome } from '$scripts/outcome';

export type RemainingOutcomes<T, THandled> = T extends THandled ? never : T;

export type OutcomeAction<TOutcome extends Outcome<any>> = (
  ...args: any[]
) => Promise<TOutcome>;

export type OutcomeActionBinder<TBase extends HasOutcome<any>> = <
  TOutcome extends Outcome<any>
>(
  base: TBase
) => OutcomeAction<TOutcome>;

export type OutcomeActionsMap<T extends HasOutcome<any>> = Record<
  string,
  OutcomeActionBinder<T>
>;

export type BoundOutcomeActionsMap<
  TBase extends HasOutcome<any>,
  TActionsMap extends OutcomeActionsMap<TBase>
> = {
  [K in keyof TActionsMap]: ReturnType<TActionsMap[K]>;
};

export type GetOutcomes<
  TActionCreator extends (...args: any[]) => OutcomeAction<any>
> = Awaited<ReturnType<ReturnType<TActionCreator>>>;

export type GetOutcomesFromActions<
  TActionCreator extends ((...args: any[]) => OutcomeAction<any>)[]
> = GetOutcomes<TActionCreator[number]>;

export function outcomeFunction<TAction extends OutcomeAction<any>>(
  originalFn: TAction
) {
  return function listener<TBase extends HasOutcome<any>>(base: TBase) {
    return async function (...args: any[]) {
      const promiseResolver = base.outcomePromise.resolve;
      const outcome = await originalFn.apply(base, args);
      promiseResolver(outcome);

      return base.outcomePromise.promise;
    } as TAction;
  };
}
