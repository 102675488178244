import { AppEvents } from '$scripts/events/events';
import { Outcome } from '$scripts/outcome';
import { queryState as qs } from '$scripts/query-state';
import { signInAdobeUrl } from '$scripts/urls/list';
import { PROVIDERS } from '$shared/types/social-provider';
import { getApplicationState } from '$store/application-store';
import { defaultHandleRedirect } from '$traits/handlers';
import {
  GetOutcomes,
  RemainingOutcomes,
  outcomeFunction,
} from '$traits/helpers';
import { RedirectOutcome } from '$traits/outcomes';

interface ActionDependencies {
  getApplicationState: typeof getApplicationState;
}

function createAction({ getApplicationState }: ActionDependencies) {
  return async function action() {
    const { events, queryState } = await getApplicationState();
    events.sendProvider(PROVIDERS.adobe);

    const url = signInAdobeUrl(qs.toObject(queryState))();
    return new RedirectOutcome({ url });
  };
}

export const outcomeAction = outcomeFunction(
  createAction({ getApplicationState })
);

export type TraitOutcome = GetOutcomes<typeof outcomeAction>;

export function handleOutcome<T extends Outcome>(
  events: AppEvents,
  outcome: T
) {
  defaultHandleRedirect(events, outcome);

  return outcome as RemainingOutcomes<T, TraitOutcome>;
}

export const trait = {
  action: outcomeAction,
  handle: handleOutcome,
};
