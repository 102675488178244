import { COUNTRIES_URL, NEAR_COUNTRY_URL } from './constants';
import {
  CountryId,
  verifyFetchCountriesResponse,
  verifyFetchNearCountryResponse,
} from '$shared/types/countries';

import { getAuthServicesEndpoint } from '../index';
import { logError } from '$shared/helpers';

export async function fetchCountries() {
  const authServicesEndpoint = await getAuthServicesEndpoint();
  return authServicesEndpoint
    .url(COUNTRIES_URL)
    .get()
    .json(r => verifyFetchCountriesResponse.parse(r));
}

export async function fetchNearCountry() {
  const authServicesEndpoint = await getAuthServicesEndpoint();
  return authServicesEndpoint
    .url(NEAR_COUNTRY_URL)
    .get()
    .json(r => {
      return verifyFetchNearCountryResponse.parse({
        ...r,
        isoCode: r.isoCode ?? 'US',
      });
    })
    .catch(err => {
      logError(err);
      return 'US' as CountryId;
    });
}
