import { QueryState } from '$scripts/query-state';
import { SocialProvider } from '$shared/types/social-provider';
import { z } from 'zod';
import { getImsEndpoint, getAuthServicesEndpoint } from '.';
import { PATHNAMES } from './constants';
import { Account } from './types/Account';

type RequestParams = {
  provider_id: SocialProvider;
  fed_token: string;
  client_id: string;
  scope: string;
  response_type: string;
};

type Params = {
  providerId: RequestParams['provider_id'];
  federatedToken: RequestParams['fed_token'];
};

export async function socialNativeHeadless(
  qs: QueryState,
  { providerId, federatedToken }: Params
): Promise<{ token: string }> {
  const imsEndpoint = await getImsEndpoint({
    client_id: qs.client_id,
    relay: qs.relay,
  });

  const promise = imsEndpoint
    .url(PATHNAMES.socialNativeHeadless)
    .post({
      provider_id: providerId,
      fed_token: federatedToken,
      client_id: qs.client_id,
      scope: qs.scope,
      response_type: 'implicit_jump',
    } as RequestParams)
    .json(res => z.object({ token: z.string() }).parse(res));

  return promise.catch(e => {
    throw new Error();
  });
}

type CompleteAccountRequest = {
  createAccountRequest: {
    fedToken: string;
  } & Account;
};

export async function socialCompleteAccount(
  qs: QueryState,
  request: CompleteAccountRequest
): Promise<any> {
  const authServicesEndpoint = await getAuthServicesEndpoint();

  const promise = authServicesEndpoint
    .url(PATHNAMES.socialCompleteAccount)
    .post(request)
    .json();

  return promise;
}
