import { trait as withPopup } from './with-popup';
import { trait as withPopupOrRedirect } from './with-popup-or-redirect';
import { trait as withRedirect } from './with-redirect';

export const PasskeySignInTrait = {
  withPopup,
  withRedirect,
  withPopupOrRedirect,
};

export interface HasPasskeySignInWithPopup {
  onPasskeySignIn: ReturnType<typeof PasskeySignInTrait.withPopup.action>;
}

export interface HasPasskeySignInWithRedirect {
  onPasskeySignIn: ReturnType<typeof PasskeySignInTrait.withRedirect.action>;
}

export interface HasPasskeySignInWithPopupOrRedirect {
  onPasskeySignIn: ReturnType<typeof PasskeySignInTrait.withPopupOrRedirect.action>;
}
