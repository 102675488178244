import {
  consumeImplicitJumpToken,
  socialHeadlessCall,
} from '$api/headless-api';
import { getQueryStateId } from '$api/query-state-api';
import { AuthRideError } from '$api/types/errors';
import { PopupBlockedError } from '$scripts/events/dispatchers';
import { AppEvents } from '$scripts/events/events';
import { CriticalOutcome, Outcome } from '$scripts/outcome';
import { queryState as qs } from '$scripts/query-state';
import {
  socialFederatedPopup,
  socialWebDeeplinkPopup,
} from '$scripts/urls/handlers';
import { socialWebDeeplinkUrl, tempestRedirectUrl } from '$scripts/urls/list';
import { AUTH_FAILED_REASON } from '$shared/types/auth-failed-reasons';
import {
  GOOGLE_MOCK_PROVIDER,
  GOOGLE_SIGN_IN_CLIENT_ID,
  PROVIDERS,
  SocialProvider,
} from '$shared/types/social-provider';
import { getApplicationState } from '$store/application-store';
import {
  defaultHandleAuthCode,
  defaultHandleAuthFailed,
  defaultHandleToken,
} from '$traits/handlers';
import {
  GetOutcomes,
  RemainingOutcomes,
  outcomeFunction,
} from '$traits/helpers';
import {
  AuthCodeOutcome,
  AuthFailedOutcome,
  CompleteAccountRequiredOutcome,
  TokenOutcome,
} from '$traits/outcomes';
import { convertError, envs } from '@sentry/shared';
import { ENVIRONMENTS } from '@sentry/shared/dist/environments';

interface ActionDependencies {
  getApplicationState: typeof getApplicationState;
}

async function getFederatedToken(socialProvider: SocialProvider) {
  const { events, eventListener, queryState } = await getApplicationState();

  if (queryState.st_mock_fedtoken) {
    return 'mocked-token';
  }

  // Use the google-mock service for federated sign-in in non-prod environments
  const socialProviderForFederated =
    queryState.client_id === GOOGLE_SIGN_IN_CLIENT_ID &&
    envs.getAppEnv() !== ENVIRONMENTS.prod
      ? GOOGLE_MOCK_PROVIDER
      : socialProvider;

  const {
    data: { token },
  } = await socialFederatedPopup(socialProviderForFederated)(
    events,
    eventListener(),
    queryState
  );

  return token;
}

function createAction({ getApplicationState }: ActionDependencies) {
  return async function action(socialProvider: SocialProvider) {
    const { events, eventListener, queryState, config } =
      await getApplicationState();
    events.sendProvider(socialProvider);

    const shouldEnableCompleteAccount =
      'enableCompleteAccount' in config ? config.enableCompleteAccount : false;

    const shouldUseFederated =
      queryState.st_use_federated ||
      shouldEnableCompleteAccount ||
      sessionStorage.getItem('sentry_use_federated') === 'true';

    try {
      if (shouldUseFederated) {
        const token = await getFederatedToken(socialProvider);
        // TODO: Call Social Headless to get IJT OR error (account creation req / others)
        // SUSI-20877: Integrate Headless Social API
        const {
          queryState: { client_id, scope },
        } = await getApplicationState();

        try {
          // for testing the user-exists flow, add the "valid_user" response_type
          const r = await socialHeadlessCall(token, socialProvider, client_id);
          const res = await consumeImplicitJumpToken(r.token, client_id);

          if (res.access_token) {
            return new TokenOutcome({ token: res.access_token });
          }

          // if there's no access_token, that means the check failed
          throw new Error();
        } catch (e: unknown) {
          if (e instanceof Error) {
            const errorMessage = JSON.parse(e.message);
            if (
              errorMessage.error === 'ride_AdobeID_social' &&
              errorMessage.error_type === 'recoverable_user'
            ) {
              const fields = errorMessage.data;
              return new CompleteAccountRequiredOutcome({
                fields,
                token,
                socialProvider,
                client_id,
              });
            } else {
              // This is handling the non-ride error case
              const { id: queryStateId } = await getQueryStateId(
                client_id,
                queryState
              );

              const tempestOriginUrl = tempestRedirectUrl({
                queryStateId,
                client_id,
              })();

              const fallbackUrl = `${tempestOriginUrl}#/social/${socialProvider}/${token}`;

              return new AuthFailedOutcome({
                reason: AUTH_FAILED_REASON.unknown,
                fallbackUrl,
              });
            }
          }

          console.error(e);
        }

        // BRANCH 1 (from social headless): TODO: Implement complete account handling
        // SUSI-20877 -> SUSI-20962: Handle Complete Account outcome
        // return new CompleteAccountRequiredOutcome({ fields, token })

        // BRANCH 2 (from social headless): On failure of Social Headless API with ride error
        if (queryState.st_exchange_failed) {
          throw new AuthRideError();
        }

        // BRANCH 3 (from social headless): TODO: Implement token exchange for IJT, return correct token
        // SUSI-20876: Integrate with IJT Session API
        return new TokenOutcome({ token });

        // TODO: Implement error handling for ride errors
        // SUSI-20880: Redirect to Tempest for errors
        // return new AuthFailedOutcome({ reason: AUTH_FAILED_REASON.rideError, fallbackUrl })
      }

      if (queryState.response_type === 'code') {
        const {
          data: { code },
        } = await socialWebDeeplinkPopup(socialProvider).withAuthCode(
          events,
          eventListener(),
          queryState
        );
        return new AuthCodeOutcome({ code });
      } else {
        const {
          data: { access_token },
        } = await socialWebDeeplinkPopup(socialProvider).withAccessToken(
          events,
          eventListener(),
          queryState
        );
        return new TokenOutcome({ token: access_token });
      }
    } catch (e) {
      const fallbackUrl = socialWebDeeplinkUrl({
        ...qs.toObject(queryState),
        provider_id: socialProvider,
      })();

      if (e instanceof PopupBlockedError) {
        return new AuthFailedOutcome({
          reason: AUTH_FAILED_REASON.popupBlocked,
          fallbackUrl,
        });
      }

      if (e instanceof AuthRideError) {
        return new AuthFailedOutcome({
          reason: AUTH_FAILED_REASON.rideError,
          fallbackUrl,
        });
      }

      const error = convertError(e);
      return new CriticalOutcome(error);
    }
  };
}

export const outcomeAction = outcomeFunction(
  createAction({ getApplicationState })
);

export type TraitOutcome = GetOutcomes<typeof outcomeAction>;

export function handleOutcome<T extends Outcome>(
  events: AppEvents,
  outcome: T
) {
  defaultHandleToken(events, outcome);
  defaultHandleAuthCode(events, outcome);
  defaultHandleAuthFailed(events, outcome);

  return outcome as RemainingOutcomes<T, TraitOutcome>;
}

export const trait = {
  action: outcomeAction,
  handle: handleOutcome,
};
