import '@spectrum-web-components/picker/sp-picker.js';
import '@spectrum-web-components/menu/sp-menu-item.js';

import { LitElement, css, html } from 'lit';
import { customElement, property, state } from 'lit/decorators';

import { COUNTRY_LIST } from '$locales/constants/countries';
import { i18n } from '$locales/helpers';
import { Country, CountryId } from '$shared/types/countries';
import { flex } from '$shared/mixins/flex';

import { CountryChangeEvent } from './types';

@customElement('country-selector')
export class CountrySelector extends LitElement {
  static styles = css`
    .country-picker-label {
      color: var(--spectrum-gray-700);
      font-size: 12px;
      height: 24px;

      margin-block-start: 2px;
    }

    .country-picker--container {
      ${flex().align('center').gap(100).build()}
    }
  `;

  @property()
  countries?: Country[];

  @property()
  selectedCountry?: CountryId;

  onCountryChange = (e: CountryChangeEvent) => {
    const countryChangeEvent = new CustomEvent('country-change', {
      bubbles: true,
      composed: true,
      detail: {
        value: e.target.value,
        ageOfConsent: this.countries?.find(
          country => country.id === e.target.value
        )?.policies?.ageOfConsent,
      },
    });

    this.dispatchEvent(countryChangeEvent);
  };

  validateAllProps() {
    if (!this.countries || !this.selectedCountry) {
      return false;
    }

    return true;
  }

  render() {
    if (!this.validateAllProps) {
      throw new Error('Missing required props');
    }

    return html`
      <div class="country-picker--container">
        <sp-field-label
          size="s"
          for="picker-s-quiet"
          class="country-picker-label"
        >
          ${i18n().formatMessage({
            id: 'country-selector.label.country-region',
            defaultMessage: 'Country/Region:',
          })}
        </sp-field-label>
        <sp-picker
          id="picker-s-quiet"
          data-id="CountrySelector"
          quiet
          size="s"
          value=${this.selectedCountry}
          @change=${this.onCountryChange}
          label="Country"
        >
          ${this.countries?.map(country => {
            return html` <sp-menu-item
              value=${country.id}
              data-id=${country.id}
            >
              ${COUNTRY_LIST[country.id]()}
            </sp-menu-item>`;
          })}
        </sp-picker>
      </div>
    `;
  }
}
