import { LitElement, css, html } from 'lit';
import { customElement } from 'lit/decorators';

import { flex } from '../../shared/mixins/flex';

import '@spectrum-web-components/progress-circle/sp-progress-circle';

@customElement('progress-circle')
export class ProgressCircle extends LitElement {
  static styles = css`
    #progress-circle-wrapper {
      width: 100%;
      height: 100%;

      ${flex().center().build()};
    }
  `;

  render() {
    return html`
      <div id="progress-circle-wrapper">
        <sp-progress-circle
          label="The page set to  be displayed is loading."
          indeterminate
          size="l"
        ></sp-progress-circle>
      </div>
    `;
  }
}
