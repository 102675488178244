import { i18n } from '$locales/helpers';

export const LINKS = {
  privacyPolicyInfoShare: () =>
    i18n().formatMessage({
      id: 'privacy-policy-info-share-link',
      defaultMessage: 'https://www.adobe.com/privacy/policy.html#info-share',
    }),
  marketingEmail: () =>
    i18n().formatMessage({
      id: 'marketing-email-link',
      defaultMessage: 'https://www.adobe.com/privacy/marketing.html#mktg-email',
    }),
  privacyPolicy: () =>
    i18n().formatMessage({
      id: 'privacy-policy-link',
      defaultMessage: 'https://www.adobe.com/privacy/policy.html',
    }),
  termsOfUse: () =>
    i18n().formatMessage({
      id: 'terms-of-use-link',
      defaultMessage: 'https://www.adobe.com/ro/legal/terms.html',
    }),
};
