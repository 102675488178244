import { PageController } from '$scripts/base-controller';

import {
  AdobeCreateAccountTrait,
  HasAdobeCreateAccountWithPopupOrRedirect,
} from '$traits/adobe-create-account';
import {
  AdobeSignInTrait,
  HasAdobeSignInWithPopupOrRedirect,
} from '$traits/adobe-sign-in';
import { GetOutcomesFromActions } from '$traits/helpers';
import { PasskeySignInTrait } from '$traits/passkey-sign-in';
import {
  HasSocialSignInWithPopupOrRedirect,
  SocialSignInTrait,
} from '$traits/social-sign-in';

import { PageState } from './state';

export type PageOutcome = GetOutcomesFromActions<
  [
    typeof AdobeSignInTrait.withPopupOrRedirect.action,
    typeof AdobeCreateAccountTrait.withPopupOrRedirect.action,
    typeof SocialSignInTrait.withPopupOrRedirect.action,
    typeof PasskeySignInTrait.withPopupOrRedirect.action
  ]
>;

export class Controller
  extends PageController<PageOutcome, PageState>
  implements
    HasSocialSignInWithPopupOrRedirect,
    HasAdobeSignInWithPopupOrRedirect,
    HasAdobeCreateAccountWithPopupOrRedirect
{
  onSignIn = AdobeSignInTrait.withPopupOrRedirect.action(this);
  onCreateAccount = AdobeCreateAccountTrait.withPopupOrRedirect.action(this);
  onSocialProviderSignIn = SocialSignInTrait.withPopupOrRedirect.action(this);
  onPasskeySignIn = PasskeySignInTrait.withPopupOrRedirect.action(this);

  toggleShowMoreProviders() {
    this.state.toggleShowMoreProviders();
  }
}
