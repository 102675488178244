import {
  CreateAccountLinkOutcome,
  DeeplinkOutcome,
  ProviderOutcome,
  SignInLinkOutcome,
  SkipLinkOutcome,
  deeplinkSocialProvidersMap,
  idpFlowQueryMap,
  unifiedMobile,
} from '$pages/variants/unified-mobile';
import { MobileEvents } from '$scripts/events/events';
import { CriticalOutcome } from '$scripts/outcome';
import {
  DEEPLINK_SOCIAL_PROVIDERS,
  PROVIDERS,
  Provider,
} from '$shared/types/social-provider';
import { InitialState as UmeButtonsState } from '$pages/variants/unified-mobile/state';
import { AsyncWrapper } from '$scripts/async-wrapper';
import { SentryError } from '@sentry/shared';

import { getConfigurationsState } from '$store/configurations-store';
import { unhandledEndOfFlow } from '../helpers/end-of-flow';
import { FlowConfig } from './schema';

export async function unifiedMobileFlow(
  events: MobileEvents,
  config: FlowConfig
): Promise<any> {
  const { fetchAndCacheSocialProvidersNoBackup, initiateHelloEvent } =
    getConfigurationsState();

  const passkeysEnabled = config.passkeysEnabled;
  const socialProvidersWhitelist = config.allowedSocialProviders;
  const providerPromise = fetchAndCacheSocialProvidersNoBackup();

  let providers = AsyncWrapper.from(
    providerPromise
      .then((socialProviders: Provider[]) =>
        socialProviders.filter(socialProvider =>
          socialProvidersWhitelist
            ? socialProvidersWhitelist.includes(socialProvider)
            : socialProvider
        )
      )
      .then((socialProviders: Provider[]) =>
        socialProviders.concat(passkeysEnabled ? [PROVIDERS.passkey] : [])
      )
  ).onError(() => {
    events.sendError(
      SentryError.unrecoverable('Social Providers retrieval error')
    );
  });

  const hasTransparentBackground = config.background === 'transparent';
  const isSkippable = config.skippable;

  const showMoreProviders = false;

  const body = document.querySelector('body');
  if (body) {
    body.classList.add('ume-body');
    body.classList.toggle('ume-body--transparent', hasTransparentBackground);
  }

  const pageState: UmeButtonsState = {
    isSkippable,
    passkeysEnabled,
    hasTransparentBackground,
    async: { providers },
    showMoreProviders,
    maxSocialProviders: 3,
  };

  const outcome = await unifiedMobile.create(pageState);

  if (outcome.of(ProviderOutcome)) {
    const { provider } = outcome.getValue();

    // Providers which are not treated natively will be treated as deeplink
    if (DEEPLINK_SOCIAL_PROVIDERS.includes(provider)) {
      events.deeplink({
        deeplink: deeplinkSocialProvidersMap.get(provider),
        query: idpFlowQueryMap.get(provider),
      });
      return unifiedMobileFlow(events, config);
    }

    events.redirect({ provider });

    return unifiedMobileFlow(events, config);
  }

  if (outcome.of(DeeplinkOutcome)) {
    const { deeplink, query } = outcome.getValue();

    events.deeplink({ deeplink, query });

    return unifiedMobileFlow(events, config);
  }

  if (
    outcome.of(SignInLinkOutcome) ||
    outcome.of(CreateAccountLinkOutcome) ||
    outcome.of(SkipLinkOutcome)
  ) {
    const { option } = outcome.getValue();

    events.redirect({ option });

    return unifiedMobileFlow(events, config);
  }

  if (outcome.of(CriticalOutcome)) {
    return events.sendError(outcome.getValue());
  }

  return unhandledEndOfFlow(outcome);
}
