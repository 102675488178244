import { css, html, LitElement, nothing } from 'lit';
import { customElement, property } from 'lit/decorators';

import { flex } from '$shared/mixins/flex';
import { ACCOUNT_TYPE, AccountType } from '$shared/types/account';
import { i18n } from '$locales/helpers';

import { defaultAvatarT1 } from '../icons/default-avatar-t1';
import { defaultAvatarT2T3 } from '../icons/default-avatar-t2t3';
import '../typography';

const getAccountTypeStrings = (): Record<AccountType, string> => ({
  individual: i18n().formatMessage({
    id: 'profile-card.label.personal-account',
    defaultMessage: 'Personal Account',
  }),
  enterprise: i18n().formatMessage({
    id: 'profile-card.label.enterprise-company-or-school-account',
    defaultMessage: 'Company or School Account',
  }),
  federated: i18n().formatMessage({
    id: 'profile-card.label.federated-company-or-school-account',
    defaultMessage: 'Company or School Account',
  }),
});

@customElement('profile-card')
export class ProfileCard extends LitElement {
  @property()
  imageSrc: string = '';

  @property()
  email: string = '';

  @property({
    type: String,
    converter: {
      fromAttribute(value) {
        return value && value in ACCOUNT_TYPE ? value : ACCOUNT_TYPE.individual;
      },
    },
  })
  accountType: AccountType = ACCOUNT_TYPE.individual;

  static styles = css`
    .profile-container {
      ${flex().align('center').build()}
    }

    .profile-picture {
      border-radius: 50%;
      display: inline-block;
      height: 64px;
      margin-right: 12px;
      overflow: hidden;
      vertical-align: middle;
      width: 64px;
    }

    .profile-details-container {
      ${flex().direction('column').align('start').build()},
    }

    .profile-email {
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      margin-top: 0;
      color: var(--spectrum-gray-800);
      word-break: break-all;
    }

    .profile-type {
      color: var(--spectrum-gray-800);
      font-size: 11px;
      line-height: 16.5px;
      word-break: break-word;
    }
  `;

  renderProfileImage() {
    if (this.imageSrc) {
      return html`
        <img src="${this.imageSrc}" alt="" class="profile-picture" />
      `;
    }

    return html`<span class="profile-picture">
      ${this.accountType === ACCOUNT_TYPE.individual
        ? defaultAvatarT1
        : defaultAvatarT2T3}
    </span>`;
  }

  renderEmail() {
    return this.email
      ? html` <div class="profile-email">${this.email}</div> `
      : nothing;
  }

  renderAccountType() {
    return html`
      <div class="profile-type">
        ${getAccountTypeStrings()[this.accountType]}
      </div>
    `;
  }

  render() {
    return html`<div class="profile-container">
      ${this.renderProfileImage()}
      <div class="profile-details-container">
        ${this.renderEmail()} ${this.renderAccountType()}
      </div>
    </div> `;
  }
}
