import { HasOutcome } from '$scripts/base-controller';
import { AppEvents } from '$scripts/events/events';
import { Outcome } from '$scripts/outcome';
import { SocialProvider } from '$shared/types/social-provider';
import { getApplicationState } from '$store/application-store';
import { GetOutcomes, RemainingOutcomes } from '$traits/helpers';
import {
  handleOutcome as handleSocialSignInWithPopup,
  outcomeAction as socialSignInWithPopupAction,
} from './with-popup';
import {
  handleOutcome as handleSocialSignInWithRedirect,
  outcomeAction as socialSignInWithRedirectAction,
} from './with-redirect';

interface ActionDependencies {
  getApplicationState: typeof getApplicationState;
}

interface CombinedOutcomeAction {
  socialSignInWithPopupAction: typeof socialSignInWithPopupAction;
  socialSignInWithRedirectAction: typeof socialSignInWithRedirectAction;
}

function createAction(
  { getApplicationState }: ActionDependencies,
  {
    socialSignInWithPopupAction,
    socialSignInWithRedirectAction,
  }: CombinedOutcomeAction
) {
  return function <TBase extends HasOutcome<any>>(base: TBase) {
    const signInWithPopup = socialSignInWithPopupAction(base);
    const signInWithRedirect = socialSignInWithRedirectAction(base);
    return async function action(socialProvider: SocialProvider) {
      const { config } = await getApplicationState();

      return config.popup
        ? signInWithPopup(socialProvider)
        : signInWithRedirect(socialProvider);
    };
  };
}

export const outcomeAction = createAction(
  { getApplicationState },
  { socialSignInWithPopupAction, socialSignInWithRedirectAction }
);

export type TraitOutcome = GetOutcomes<typeof outcomeAction>;

export function handleOutcome<T extends Outcome>(
  events: AppEvents,
  outcome: T
) {
  outcome = handleSocialSignInWithPopup(events, outcome);
  outcome = handleSocialSignInWithRedirect(events, outcome);

  return outcome as RemainingOutcomes<T, TraitOutcome>;
}

export const trait = {
  action: outcomeAction,
  handle: handleOutcome,
};
