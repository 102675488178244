import { PageController } from '$scripts/base-controller';

import {
  AdobeSignInTrait,
  HasAdobeSignInWithPopupOrRedirect,
} from '$traits/adobe-sign-in';
import { GetOutcomesFromActions } from '$traits/helpers';
import {
  HasSocialSignInWithPopupOrRedirect,
  SocialSignInTrait,
} from '$traits/social-sign-in';
import { PageState } from './state';

export type PageOutcome = GetOutcomesFromActions<
  [
    typeof AdobeSignInTrait.withPopupOrRedirect.action,
    typeof SocialSignInTrait.withPopupOrRedirect.action
  ]
>;

export class Controller
  extends PageController<PageOutcome, PageState>
  implements
    HasSocialSignInWithPopupOrRedirect,
    HasAdobeSignInWithPopupOrRedirect
{
  onSignIn = AdobeSignInTrait.withPopupOrRedirect.action(this);
  onSocialProviderSignIn = SocialSignInTrait.withPopupOrRedirect.action(this);

  toggleShowMoreProviders() {
    this.state.toggleShowMoreProviders();
  }
}
