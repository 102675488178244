import { trait as withPopup } from './with-popup';
import { trait as withPopupOrRedirect } from './with-popup-or-redirect';
import { trait as withRedirect } from './with-redirect';

export const AdobeCreateAccountTrait = {
  withPopup,
  withRedirect,
  withPopupOrRedirect,
};

export interface HasAdobeCreateAccountWithPopup {
  onCreateAccount: ReturnType<typeof AdobeCreateAccountTrait.withPopup.action>;
}

export interface HasAdobeCreateAccountWithRedirect {
  onCreateAccount: ReturnType<
    typeof AdobeCreateAccountTrait.withRedirect.action
  >;
}

export interface HasAdobeCreateAccountWithPopupOrRedirect {
  onCreateAccount: ReturnType<
    typeof AdobeCreateAccountTrait.withPopupOrRedirect.action
  >;
}
